import { Address } from '../types/Location';
import { Promotion } from '../types/Promotion';
import { isFuture, parseISO } from 'date-fns';
import { Station } from '../types/Station';
import { JobTitle } from '../types/enums/JobTitle';
import { ConfirmCodeAction } from '../types/enums/ConfirmCodeAction';
import isEmpty from 'lodash/isEmpty';

/**
 * Checks whether the provided value is a function.
 *
 * @param v the value to check
 * @returns true if it is a function, false otherwise
 */
export const isFunction = (v: any): v is Function => typeof v === 'function';

/**
 * Checks if the given array is empty or not.
 * Return true if the given array is undefined or null.
 *
 * @param value the value to check
 * @returns true if empty, false otherwise
 */
export const isEmptyArray = <T>(value: T[] | undefined | null): boolean => {
  return !!value && value.length === 0;
};

/**
 * Builds a string from the given location.
 *
 * @param location location object
 * @returns address separated by commas
 */
export const formatTknLocation = (location?: Address): string =>
  [location?.city, location?.state].filter(Boolean).join(', ');

/**
 * Builds a street address string from the given location.
 *
 * @param address address object
 * @returns address separated by commas
 */
export const formatTknStreetAddress = (address?: Address): string =>
  [address?.line1, address?.line2].filter(Boolean).join(' ');

/**
 * Builds a string from the given location.
 *
 * @param location location object
 * @returns address separated
 */
export const formatUserLocation = (location?: Address): string =>
  [location?.city, location?.state, location?.postCode, location?.country]
    .filter(Boolean)
    .join(' ');

/**
 * Return a sum of all numbers in given array.
 *
 * @param array array
 * @returns sum of all numbers in array
 */
export const arraySum = (array: number[]): number =>
  array.reduce((a, b) => a + b, 0);

/*
 * Calculate the appropriate inset value for given number of pixels.
 *
 * @param n number of pixels
 * @returns calculated number of pixels
 */
export const calculateiOSInset = (n: number) =>
  `calc(${n}px + env(safe-area-inset-top))`;

/**
 * Checks whether the array of objects contains any duplicate values.
 * The objects are checked based on their field values.
 *
 * @param arr asdd
 */
export const hasDuplicateValues = <T extends Record<string, any>>(
  arr: T[]
): boolean => {
  if (arr.length === 0) {
    return false;
  }
  const keys = Object.keys(arr[0]);
  const result = {} as Record<string, boolean>;
  for (let element of arr) {
    let keyForCurrentElement = '';
    keys.forEach((key: string) => {
      keyForCurrentElement += element[key];
    });
    if (result[keyForCurrentElement as keyof typeof result]) {
      return true;
    } else {
      result[keyForCurrentElement as keyof typeof result] = true;
    }
  }
  return false;
};

export const isPromotionActive = (promotion: Promotion) =>
  promotion.startTime ? isFuture(parseISO(promotion.startTime)) : false;

export const canHaveInventory = (station: Station) =>
  station.team === JobTitle.FOOD_AND_BEVERAGE ||
  station.team === JobTitle.MERCHANDISE;

export const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

export const getTotalActionSteps = (
  action: ConfirmCodeAction,
  token: string
) => {
  let totalSteps = 2;
  if (action === ConfirmCodeAction.REGISTER) {
    totalSteps = token ? 4 : 5;
  }

  return totalSteps;
};
export const isPreoptimizedSrc = (src: string) => {
  const preoptimizedDomains = [
    'storage.googleapis.com',
    'nfts.sandbox.fortressapi.com',
    'vault.sandbox.fortressapi.com',
  ];

  if (isEmpty(src)) {
    return false;
  }

  return preoptimizedDomains.some((domain) => src.includes(domain));
};

export const DEFAULT_IMG = '/images/draft.png';
