import {
  ReactNode,
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { useAuth } from './useAuth';
import {
  PushNotificationPayload,
  PushNotificationType,
} from '../types/PushNotificationPayload';
import { notificationApiService } from '../services/api/NotificationApiService';
import { getMessaging, MessagePayload, onMessage } from 'firebase/messaging';
import { TokenUserRole } from '../types/enums/TokenUserRole';
import * as Sentry from '@sentry/nextjs';
import { isGuest } from '../utils/tkn-user-util';

interface PushNotificationContextType {
  messages: PushNotificationPayload[];
  pushLocalNotification: (message: PushNotificationPayload) => void;
  clearNotificationById: (id: string) => void;
  clearMyNfeNotifications: () => void;
  clearWalletNotifications: () => void;
}

interface PushNotificationProviderProps {
  children: ReactNode;
}

const PushNotificationContext = createContext<PushNotificationContextType>({
  messages: [],
  pushLocalNotification: () => {},
  clearNotificationById: () => {},
  clearMyNfeNotifications: () => {},
  clearWalletNotifications: () => {},
});

export const PushNotificationProvider = ({
  children,
}: PushNotificationProviderProps) => {
  const { user, setUser } = useAuth();
  const [messages, setMessages] = useState<PushNotificationPayload[]>([]);
  const clearNotificationById = useCallback(
    (id: string) => {
      setMessages(messages.filter((message) => message.id !== id));
    },
    [setMessages, messages]
  );
  const pushLocalNotification = useCallback(
    (message: PushNotificationPayload) => {
      setMessages([...messages, message]);
    },
    [setMessages, messages]
  );

  const handleIncomingPayload = useCallback(
    (payload: MessagePayload) => {
      if (payload && payload.data) {
        const message: PushNotificationPayload = {
          id: payload.messageId,
          title: payload.data.title,
          body: payload.data.body,
          type: payload.data.type as PushNotificationType,
          data: payload.data.data,
        };
        pushLocalNotification(message);
      }
    },
    [pushLocalNotification]
  );

  const handleNotifications = useCallback(
    async (user) => {
      try {
        if (isGuest(user)) {
          await notificationApiService.unsubscribe();
        } else {
          const fbSw = await navigator.serviceWorker.register(
            '/firebase-messaging-sw.js'
          );
          await fbSw.update();
          await notificationApiService.subscribe();
          const messaging = getMessaging();
          onMessage(messaging, handleIncomingPayload);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [handleIncomingPayload]
  );

  useEffect(() => {
    if (user) {
      handleNotifications(user);
    }

    // We only want to run this effect when the user changes.
    // We only need to check the Sora ID, because the user object is a new object every time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.soraId, handleNotifications]);

  const clearMyNfeNotifications = useCallback(async () => {
    if (user?.role === TokenUserRole.FAN) {
      try {
        await notificationApiService.clearNfeNotifications();
        setUser({
          ...user,
          unseenMyNfeNotifications: 0,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [user, setUser]);

  const clearWalletNotifications = useCallback(async () => {
    if (user?.role === TokenUserRole.FAN) {
      try {
        await notificationApiService.clearWalletNotifications();
        setUser({
          ...user,
          unseenWalletNotifications: 0,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [user, setUser]);

  const memoizedValues = useMemo(
    () => ({
      messages,
      pushLocalNotification,
      clearNotificationById,
      clearMyNfeNotifications,
      clearWalletNotifications,
    }),
    [
      messages,
      pushLocalNotification,
      clearNotificationById,
      clearMyNfeNotifications,
      clearWalletNotifications,
    ]
  );

  return (
    <PushNotificationContext.Provider value={memoizedValues}>
      {children}
    </PushNotificationContext.Provider>
  );
};

export const usePushNotifications = () => useContext(PushNotificationContext);
