import { useMemo } from 'react';
import { useAuth, CurrentUser } from './useAuth';
import { localStorageService } from '../services/LocalStorageService';
import { TknBackendApiOption } from '../types/enums/TknBackendApiOption';
import { JobTitle } from '../types/enums/JobTitle';
import { TokenUserRole } from '../types/enums/TokenUserRole';
import { Fan } from '../types/User';

export default function useCurrentUser<T extends CurrentUser>() {
  const currentUser = useAuth().user as T;
  const backend = localStorageService.getBackendApiOption();

  const user: T = useMemo(() => {
    const memoUser =
      backend === TknBackendApiOption.REAL
        ? currentUser
        : ({
            role: localStorageService.getCurrentUserRole(),
            nickname: 'John',
            firstName: 'John',
            lastName: 'Doe',
            jobTitle:
              localStorageService.getCurrentStaffJob() || JobTitle.SECURITY,
            stations: ['1'],
          } as T);

    if (
      memoUser?.role === TokenUserRole.FAN &&
      backend === TknBackendApiOption.MOCK
    ) {
      (memoUser as Fan).unseenMyNfeNotifications = 1;
      (memoUser as Fan).unseenWalletNotifications = 1;
    }

    return memoUser;
  }, [backend, currentUser]);

  return user;
}
