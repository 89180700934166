import { Button, alpha, useTheme, SxProps, Theme } from '@mui/material';
import { forwardRef } from 'react';

export type TknButtonVariant = 'white' | 'yellow' | 'black' | 'blue';

export interface TknButtonProps {
  id?: string;
  variant?: 'text' | 'contained' | 'outlined';
  styleVariant?: TknButtonVariant;
  size?: 'small' | 'medium' | 'large';
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  label?: JSX.Element | string;
  disabled?: boolean;
  noWrap?: boolean;
  onClick?: () => void;
}

type TknButtonStyleArgs = {
  props: TknButtonProps;
  theme: Theme;
};

type TknButtonStyle = (args: TknButtonStyleArgs) => SxProps;

function getContainedStyle(theme: Theme, variant?: TknButtonVariant) {
  switch (variant) {
    case 'yellow':
      return {
        color: theme.palette.global.black,
        bgcolor: theme.palette.global.yellow,
        borderColor: theme.palette.global.black,
      };

    case 'black':
      return {
        color: theme.palette.global.white,
        bgcolor: theme.palette.global.black,
        borderColor: theme.palette.global.black,
      };

    case 'blue':
      return {
        color: theme.palette.global.white,
        bgcolor: theme.palette.global.blue,
        borderColor: theme.palette.global.black,
      };

    case 'white':
      return {
        color: theme.palette.global.black,
        bgcolor: theme.palette.global.white,
        borderColor: theme.palette.global.black,
      };
  }

  return {};
}

function getDefaultStyle(props: TknButtonProps, theme: Theme) {
  const key = props.styleVariant ?? 'white';

  const color = props.textColor ?? theme.palette.global[key];
  const borderColor = props.borderColor ?? theme.palette.global[key];

  switch (props.variant) {
    case 'text':
      return {
        color,
        bgcolor: 'transparent',
        borderColor: 'transparent',
      };

    case 'outlined':
      return {
        color,
        bgcolor: 'transparent',
        borderColor,
      };

    case 'contained':
      return getContainedStyle(theme, key);
  }

  return {};
}

function getSizeStyle(props: TknButtonProps) {
  switch (props.size) {
    case 'large':
      return {
        width: '100%',
        height: 48,
      };
    default:
      return {};
  }
}

const style: TknButtonStyle = ({ props, theme }) => {
  const defaultStyle = getDefaultStyle(props, theme);
  const color = props.textColor ?? defaultStyle.color;
  const bgcolor = props.backgroundColor ?? defaultStyle.bgcolor;
  const borderColor = props.borderColor ?? defaultStyle.borderColor;
  const { width, height } = getSizeStyle(props);

  let disabledColor = color;
  let disabledBgColor = bgcolor;
  let disabledBorderColor = borderColor;

  if (color !== 'transparent') {
    disabledColor = alpha(color as string, 0.6);
  }

  if (bgcolor !== 'transparent') {
    disabledBgColor = alpha(bgcolor as string, 0.3);
  }

  if (borderColor !== 'transparent') {
    disabledBorderColor = alpha(borderColor as string, 0.6);
  }

  return {
    color,
    bgcolor,
    borderColor,
    width,
    height,
    borderRadius: 50,
    textTransform: 'none',
    typography: 'bodyRegular',
    ...(props.noWrap && { whiteSpace: 'nowrap' }),
    '&:active': {
      boxShadow: 'none',
    },
    '&:hover': {
      color,
      bgcolor,
      borderColor,
    },
    '&.Mui-disabled': {
      color: disabledColor,
      bgcolor: disabledBgColor,
      borderColor: disabledBorderColor,
    },
  };
};

export const TknButton = forwardRef<HTMLButtonElement, TknButtonProps>(
  (props, ref) => {
    const theme = useTheme();

    return (
      <Button
        id={props.id}
        ref={ref}
        variant={props.variant}
        disabled={props.disabled}
        size={props.size}
        sx={style({ props, theme })}
        onClick={props.onClick}
      >
        {props.label}
      </Button>
    );
  }
);

TknButton.displayName = 'TknButton';
