import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { TknBackendApiOption } from '../../types/enums/TknBackendApiOption';
import { localStorageService } from '../LocalStorageService';
import * as Sentry from '@sentry/nextjs';

export abstract class HttpService {
  private abortController: AbortController = new AbortController();
  private readonly baseURL: string = process.env
    .NEXT_PUBLIC_TKN_MOCK_BACKEND_API_URL as string;
  protected instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: this.baseURL,
      signal: this.abortController.signal,
    });

    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest);
  };

  public abortPreviousRequest = () => {
    // We have to create a new AbortController,
    // each time because the old one is already aborted.
    // We also need to create a new Axios instance,
    // because the old one won't be able to abort the request.
    this.abortController.abort();
    this.abortController = new AbortController();
    this.instance = axios.create({
      baseURL: this.baseURL,
      signal: this.abortController.signal,
    });

    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  };

  private handleRequest = (config: AxiosRequestConfig) => {
    this.updateBaseUrlBasedOnSelection(config);
    if (config.headers) {
      config.headers[
        'Authorization'
      ] = `Bearer ${localStorageService.getBearerToken()}`;
      config.headers['Content-Type'] = 'application/json';
    } else {
      config.headers = {
        Authorization: `Bearer ${localStorageService.getBearerToken()}`,
        'Content-Type': 'application/json',
      };
    }
    return config;
  };

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (r) => r.data,
      (err) => {
        const { response } = err;
        Sentry.captureException(err);
        
        return Promise.reject({
          statusCode: response?.status,
          ...response.data
        });
      }
    );
  };

  private updateBaseUrlBasedOnSelection = (config: AxiosRequestConfig) => {
    const selectedBaseURL =
      localStorageService.getBackendApiOption() === TknBackendApiOption.MOCK
        ? process.env.NEXT_PUBLIC_TKN_MOCK_BACKEND_API_URL
        : process.env.NEXT_PUBLIC_TKN_BACKEND_API_URL;
    config.baseURL = selectedBaseURL;
  };
}
