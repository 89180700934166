import Image from 'next/image';
import { isPreoptimizedSrc } from '../utils/tkn-util';

interface TknSvgIconProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

export const TknSvgIcon = ({
  src = '',
  alt = '',
  width = 24,
  height = 24,
  onClick,
}: TknSvgIconProps) => {
  return (
    <Image
      src={src}
      alt={alt}
      height={height}
      width={width}
      onClick={onClick}
      {...(isPreoptimizedSrc(src) && { unoptimized: true })}
    />
  );
};
