import { TokenUserRole } from './enums/TokenUserRole';

export const AUTH_ROUTES = [
  () => '/create-account',
  () => '/register',
  () => '/login',
];

export const PUBLIC_ROUTES = [
  () => '/404',
  () => '/',
  () => '/desktop',
  () => '/backend-api-switcher',
  () => '/api/firebase-config',
  () => '/api/health',
  () => '/confirm-code',
  ...AUTH_ROUTES,
];

export const SHARED_ROUTES_BY_ALL_ROLES = [
  () => '/faq',
  () => '/get-in-touch',
  () => '/profile',
  () => '/profile/edit',
  () => '/messages',
  () => '/logout',
  ...PUBLIC_ROUTES,
];

export const ROUTES_BY_USER_ROLE = {
  [TokenUserRole.MANAGER]: [
    () => '/manager/home',
    () => '/payment-methods',
    (...args: string[]) => `/events/${args[0]}/edit`,
    (...args: string[]) => `/events/${args[0]}/cancel`,
    (...args: string[]) => `/events/${args[0]}/preview`,
    (...args: string[]) => `/events/${args[0]}/sales-activity`,
    (...args: string[]) => `/events/${args[0]}/sales-activity/${args[1]}`,
    () => '/events/create',
    () => '/manager/scan-nfe',
    () => '/events/confirmed-cancellation',
    () => '/manager/team',
    () => '/manager/promotion',
    () => '/manager/promotion/add',
    () => '/manager-invite',
    (...args: string[]) => `/manager/promotion/${args[0]}/edit`,
    () => '/manager/stations',
    (...args: string[]) => `/manager/stations/${args[0]}/sales-activity`,
    (...args: string[]) => `/manager/products/${args[0]}/sales-activity`,
    () => '/manager/stations/overview',
    () => '/manager/guest-list',
    (...args: string[]) => `/manager/guest-list/${args[0]}`,
    ...SHARED_ROUTES_BY_ALL_ROLES,
  ],
  [TokenUserRole.FAN]: [
    () => '/fan/home',
    () => '/payment-methods',
    (...args: string[]) => `/payment/events/${args[0]}`,
    (...args: string[]) => `/events/${args[0]}`,
    (...args: string[]) => `/events/${args[0]}/transfer-nfe`,
    (...args: string[]) => `/events/${args[0]}/sell-nfe`,
    (...args: string[]) => `/events/${args[0]}/purchase-complete`,
    () => '/wallet',
    () => '/my-nfes',
    (...args: string[]) => `/events/${args[0]}/overview`,
    () => '/nft-request',
    ...SHARED_ROUTES_BY_ALL_ROLES,
  ],
  [TokenUserRole.STAFF]: [
    ...SHARED_ROUTES_BY_ALL_ROLES,
    () => '/staff/home',
    (...args: string[]) => `/events/${args[0]}/preview`,
    (...args: string[]) => `/staff/scan-nfe/${args[0]}`,
    (...args: string[]) => `/staff/stations/${args[0]}`,
  ],
  [TokenUserRole.GUEST]: [
    ...SHARED_ROUTES_BY_ALL_ROLES,
    () => '/guest/home',
    (...args: string[]) => `/events/${args[0] ?? '[event]'}`,
  ],
};
