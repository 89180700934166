import { HttpService } from './HttpService';

class EngagementQuestionApiService extends HttpService {
  private readonly submitAnswerUrl = '/api/submit-answer';

  public submitAnswer(questionId: string, answer: string): Promise<void> {
    return this.instance.post(this.submitAnswerUrl, { questionId, answer });
  }
}

export const engagementQuestionApiService = new EngagementQuestionApiService();
