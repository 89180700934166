import { Gender } from './enums/Gender';
import { JobTitle } from './enums/JobTitle';
import { NftFileType } from './enums/NftFileType';
import { PaymentMethod } from './enums/PaymentMethod';
import { TokenUserRole } from './enums/TokenUserRole';
import { TShirtSize } from './enums/TShirtSize';
import { Address } from './Location';

export interface User {
  soraId: string;
  nickname: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  preferableEmailAddress: string;
  emailAddress: string;
  phoneNumber: string;
  role: TokenUserRole;
  userCreated?: boolean;
  termsAndConditions?: boolean;
  researchPurposes?: boolean;
  paymentMethod: PaymentMethod | null;
}

export interface Fan extends User {
  shareMyName: boolean;
  shareMyAddress: boolean;
  location: Address;
  shareMyDateOfBirth: boolean;
  shareMyZipCode: boolean;
  zipCode: number | string;
  shareMyGender: boolean;
  gender: Gender;
  shareMyTShirtSize: boolean;
  tShirtSize: TShirtSize;
  chooseYourNft: NftFileType;
  shareMyNft: boolean;
  unseenMyNfeNotifications: number;
  unseenWalletNotifications: number;
}

export interface Manager extends User {
  businessAddress: Address;
  venueOrganization: string;
}

export interface Staff extends User {
  venueOrganization: string;
  jobTitle: JobTitle;
  stations: string[];
}

export interface Guest extends User {}

const testUser: User = {
  firstName: 'John',
  lastName: 'Doe',
  soraId: 'sora-user-1',
  nickname: 'John Doe',
  dateOfBirth: '1995-05-22',
  preferableEmailAddress: 'test@test.com',
  emailAddress: 'test@test.com',
  role: TokenUserRole.FAN,
  phoneNumber: '011332221',
  termsAndConditions: false,
  researchPurposes: true,
  userCreated: true,
  paymentMethod: null,
};

export const testFanUser: Fan = {
  ...testUser,
  role: TokenUserRole.FAN,
  dateOfBirth: '1995-05-22',
  gender: Gender.MALE,
  location: {
    line1: 'Hello Street',
    line2: '7',
    city: 'Subotica',
    state: 'NY',
    postCode: '24000',
  },
  shareMyName: true,
  shareMyAddress: true,
  shareMyDateOfBirth: true,
  shareMyZipCode: true,
  zipCode: 24000,
  shareMyGender: true,
  shareMyTShirtSize: true,
  tShirtSize: TShirtSize.XXL,
  chooseYourNft: NftFileType.VIDEO,
  shareMyNft: true,
  paymentMethod: null,
  unseenMyNfeNotifications: 0,
  unseenWalletNotifications: 0,
};

export const testManagerUser: Manager = {
  ...testUser,
  role: TokenUserRole.MANAGER,
  venueOrganization: 'Bright Events',
  businessAddress: {
    line1: 'Hello Street',
    line2: '7',
    city: 'Subotica',
    state: 'Vojvodina',
    postCode: '24000',
  },
  paymentMethod: null,
};

export const testStaffUser: Staff = {
  ...testUser,
  role: TokenUserRole.STAFF,
  venueOrganization: 'Bright Events',
  jobTitle: JobTitle.SECURITY,
  stations: ['1'],
};
