import {
  useState,
  useMemo,
  useCallback,
  createContext,
  useContext,
  ReactNode,
} from 'react';
import { PaymentMethodUpdateStatus } from '../types/enums/PaymentMethodUpdateStatus';
import { mxApiService } from '../services/api/MXApiService';
import { MXWidget } from '../components/MXWidget';
import { MXEvent } from '../types/mx/mx';
import * as Sentry from '@sentry/nextjs';

interface MXContextType {
  status: PaymentMethodUpdateStatus;
  openWidget: () => void;
  closeWidget: () => void;
}

interface MXProviderProps {
  children: ReactNode;
}

const MXContext = createContext<MXContextType>({} as MXContextType);

export const MXProvider = ({ children }: MXProviderProps) => {
  const [widgetUrl, setWidgetUrl] = useState('');
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [paymentMethodUpdateStatus, setPaymentMethodUpdateStatus] =
    useState<PaymentMethodUpdateStatus>(PaymentMethodUpdateStatus.NONE);
  const shouldRenderWidget = useMemo(() => {
    return (
      paymentMethodUpdateStatus === PaymentMethodUpdateStatus.PENDING &&
      isWidgetOpen &&
      widgetUrl !== ''
    );
  }, [isWidgetOpen, paymentMethodUpdateStatus, widgetUrl]);

  const openWidget = useCallback(() => {
    mxApiService
      .getWidgetUrl()
      .then((url) => {
        setWidgetUrl(url);
        setIsWidgetOpen(true);
        setPaymentMethodUpdateStatus(PaymentMethodUpdateStatus.PENDING);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setPaymentMethodUpdateStatus(PaymentMethodUpdateStatus.FAILED);
      });
  }, []);

  const closeWidget = useCallback(() => {
    setWidgetUrl('');
    setIsWidgetOpen(false);
    setPaymentMethodUpdateStatus(PaymentMethodUpdateStatus.NONE);
  }, []);

  const mxHandler = useCallback(async (type: string, data: MXEvent) => {
    if (type === 'mx/connect/memberConnected') {
      try {
        await mxApiService.addMember({
          memberGuid: data.member_guid,
        });
        setPaymentMethodUpdateStatus(PaymentMethodUpdateStatus.SUCCESS);
      } catch (e) {
        Sentry.captureException(e);
        setPaymentMethodUpdateStatus(PaymentMethodUpdateStatus.FAILED);
      }
    }
  }, []);

  return (
    <MXContext.Provider
      value={{
        status: paymentMethodUpdateStatus,
        openWidget,
        closeWidget,
      }}
    >
      {shouldRenderWidget && <MXWidget onEvent={mxHandler} url={widgetUrl} />}
      {children}
    </MXContext.Provider>
  );
};

export const useMX = () => useContext(MXContext);
