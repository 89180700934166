import { PushNotificationPayload } from '../../types/PushNotificationPayload';
import { TknDialogWithImage } from '../dialog/extensions/TknDialogWithImage';
import { useMemo } from 'react';
import { Typography, Box, SxProps } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { TknRadioButtons } from '../form/TknRadioButtons';
import { engagementQuestionApiService } from '../../services/api/EngagementQuestionApiService';

interface EngagementQuestionData {
  image: string;
  questionId: string;
  questionOptions: string[];
}

interface EngagementQuestionProps {
  message: PushNotificationPayload;
  onClose: () => void;
}

interface EngagementQuestionStyle {
  container: SxProps;
}

const style: EngagementQuestionStyle = {
  container: {
    mt: '27px',
    mb: '26px',
    '& > div > div': {
      gap: '13px',
      flexDirection: 'column',
    },
  },
};

export const EngagementQuestion = ({
  message,
  onClose,
}: EngagementQuestionProps) => {
  const { control, watch, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      question: '',
    },
  });
  const { t } = useTranslation('common'); // We should load the common namespace on each page anyway...

  const disabled = watch('question') === '' || formState.isSubmitting;

  const data: EngagementQuestionData = useMemo(() => {
    return JSON.parse(message.data);
  }, [message]);

  const actions = useMemo(
    () => [
      {
        label: t('submit'),
        action: () => {
          handleSubmit(({ question }) => {
            engagementQuestionApiService
              .submitAnswer(data.questionId, question)
              .then()
              .catch()
              .then(() => {
                onClose();
              });
          })();
        },
        disabled,
      },
    ],
    [onClose, t, disabled, handleSubmit, data.questionId]
  );

  const radioButtonsOptions = useMemo(
    () =>
      data.questionOptions.map((option) => ({
        label: option,
        id: option,
      })),
    [data]
  );

  return (
    <TknDialogWithImage
      open={true}
      id={'question'}
      variant="dark"
      actions={actions}
      imageSrc={data.image}
    >
      <Typography variant="bodyRegular">{message.body}</Typography>
      <Box sx={style.container}>
        <TknRadioButtons
          id={'question'}
          layout="grid"
          control={control}
          radioButtonsOptions={radioButtonsOptions}
        />
      </Box>
    </TknDialogWithImage>
  );
};
