import { useEffect, CSSProperties } from 'react';
import { alpha, useTheme } from '@mui/material';
import { MXEvent } from '../types/mx/mx';
import { useMX } from '../hooks/useMX';

interface MXWidgetStyle {
  container: (backgroundColor: string) => CSSProperties;
  iframe: CSSProperties;
}

interface MXWidgetProps {
  onEvent: (type: string, payload: MXEvent) => void;
  url: string;
}

const style: MXWidgetStyle = {
  container: (backgroundColor: string) => ({
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    top: '0',
    left: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor,
    zIndex: 999,
  }),
  iframe: {
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    maxHeight: '500px',
    border: 'none',
    borderRadius: '4px',
    zIndex: 1000,
  },
};

export function MXWidget({ onEvent, url }: MXWidgetProps) {
  const theme = useTheme();
  const { closeWidget } = useMX();

  useEffect(() => {
    const mxConnect = new MXConnect({
      id: 'mx-widget',
      iframeTitle: 'MX Connect',
      config: {
        ui_message_version: 4,
      },
      targetOrigin: '*',
    });

    function postMessageHandler(event: MessageEvent) {
      if (event.data.mx) {
        const { type, metadata } = event.data;
        onEvent(type, metadata);
      }
    }

    if (url) {
      mxConnect.load(url);
      window.addEventListener('message', postMessageHandler);
    }

    return () => {
      window.removeEventListener('message', postMessageHandler);
    };
  }, [url, onEvent]);

  return (
    <div
      onClick={closeWidget}
      style={style.container(alpha(theme.palette.global.black as string, 0.3))}
    >
      <div id="mx-widget" style={style.iframe}></div>
    </div>
  );
}
