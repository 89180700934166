import { Snackbar, useMediaQuery } from '@mui/material';
import React from 'react';
import { calculateiOSInset } from '../../utils/tkn-util';

export type TknSnackbarVariant = 'black' | 'yellow';

interface TknSnackbarProps {
  anchorOrigin?: {
    horizontal: 'left' | 'center' | 'right';
    vertical: 'top' | 'bottom';
  };
  open?: boolean;
  onClose?: () => void;
  children?: any;
  variant?: TknSnackbarVariant;
  snackbarKey?: string;
}

function getVariantStyling(variant?: TknSnackbarVariant) {
  switch (variant) {
    case 'yellow':
      return {
        bgcolor: 'global.yellow',
        color: 'global.black',
      };
    case 'black':
    default:
      return {
        bgcolor: 'global.black',
        color: 'global.white',
      };
  }
}

export function TknSnackbar({
  snackbarKey,
  anchorOrigin,
  open = false,
  onClose = () => {},
  variant,
  children,
}: TknSnackbarProps) {
  const isGreaterThan600 = useMediaQuery('(min-width:600px)');

  return (
    <Snackbar
      key={snackbarKey}
      id={snackbarKey}
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={onClose}
      sx={{
        ...getVariantStyling(variant),
        borderRadius: 4,
        mt: calculateiOSInset(0), // This will only take the safe-area-inset into account
        ...(isGreaterThan600 ? { maxWidth: '94%', width: '100%' } : null),
      }}
    >
      {children}
    </Snackbar>
  );
}
