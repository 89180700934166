import { Grid, Typography } from '@mui/material';
import { TknSnackbar, TknSnackbarVariant } from '../snackbars/TknSnackbar';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface SnackbarWithIconProps {
  anchorOrigin?: {
    horizontal: 'left' | 'center' | 'right';
    vertical: 'top' | 'bottom';
  };
  title: string;
  icon: React.ReactElement;
  iconColor: string;
  variant: TknSnackbarVariant;
  onClose?: () => void;
  onCloseIconClick?: () => void;
  children: React.ReactNode;
  id: string;
}

export const TknSnackbarWithIcon: React.FC<SnackbarWithIconProps> = ({
  title,
  id,
  onClose,
  onCloseIconClick,
  icon,
  iconColor,
  variant,
  children,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
}) => {
  return (
    <TknSnackbar
      anchorOrigin={anchorOrigin}
      open={true}
      onClose={() => onClose?.()}
      variant={variant}
      snackbarKey={id}
    >
      <Grid container p={2} gap={2} flexWrap="nowrap">
        <Grid item color={iconColor}>
          {icon}
        </Grid>
        <Grid container item direction="column">
          <Grid item>
            <Typography id={`message-${id}Title`} variant="bodyBold">
              {title}
            </Typography>
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
        <Grid item>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              if (onCloseIconClick) {
                onCloseIconClick();
              }
              onClose?.();
            }}
          />
        </Grid>
      </Grid>
    </TknSnackbar>
  );
};
