import { CurrentUser } from '../hooks/useAuth';
import { InvitationDetails } from '../types/auth/InvitationDetails';
import { AuthStep } from '../types/enums/AuthStep';
import { JobTitle } from '../types/enums/JobTitle';
import { TknBackendApiOption } from '../types/enums/TknBackendApiOption';
import { TokenUserRole } from '../types/enums/TokenUserRole';
import { User } from '../types/User';

class LocalStorageService {
  private static readonly authTokenName = 'TKN_JWT_TOKEN';
  private static readonly tknBackendApi = 'TKN_BACKEND_API';
  private static readonly userRoleName = 'TKN_USER_ROLE';
  private static readonly userId = 'TKN_USER_ID';
  private static readonly needHelpHidden = 'NEED_HELP_HIDDEN';
  private static readonly staffJobTitle = 'TKN_STAFF_JOB';
  private static readonly guestModeEnabled = 'GUEST_MODE_ENABLED';
  private static readonly userName = 'TKN_USER_NAME';
  private static readonly phoneNumber = 'TKN_PHONE_NUMBER';
  private static readonly emailAddress = 'TKN_EMAIL_ADDRESS';
  private static readonly invitationToken = 'INVITATION_TOKEN';
  private static readonly invitationDetails = 'INVITATION_DETAILS';
  private static readonly international = 'INTERNATIONAL';
  private static readonly authFlowUser = 'TKN_AUTH_FLOW_USER';
  private static readonly authFlowStep = 'TKN_AUTH_FLOW_STEP';
  private static readonly authFlowRedirectUrl = 'TKN_AUTH_FLOW_REDIRECT_URL';
  private static readonly desktopOverride = 'TKN_DESKTOP_OVERRIDE';

  public getBearerToken() {
    return localStorage.getItem(LocalStorageService.authTokenName);
  }

  public setBearerToken(token: string) {
    return localStorage.setItem(LocalStorageService.authTokenName, token);
  }

  public setUserId(userId: string) {
    return localStorage.setItem(LocalStorageService.userId, userId);
  }
  public getUserId() {
    return localStorage.getItem(LocalStorageService.userId);
  }

  public getBackendApiOption() {
    return (
      (localStorage.getItem(
        LocalStorageService.tknBackendApi
      ) as TknBackendApiOption) ?? TknBackendApiOption.REAL
    );
  }

  public setBackendApiOption(apiOption: TknBackendApiOption) {
    localStorage.setItem(LocalStorageService.tknBackendApi, apiOption);
  }

  public getCurrentUserRole() {
    return localStorage.getItem(
      LocalStorageService.userRoleName
    ) as TokenUserRole;
  }

  public setCurrentUserRole(role: TokenUserRole) {
    return localStorage.setItem(LocalStorageService.userRoleName, role);
  }

  public getCurrentStaffJob() {
    return localStorage.getItem(LocalStorageService.staffJobTitle) as JobTitle;
  }

  public setCurrentStaffJob(job: JobTitle) {
    return localStorage.setItem(LocalStorageService.staffJobTitle, job);
  }

  public setNeedHelpHidden(value: string) {
    return localStorage.setItem(LocalStorageService.needHelpHidden, value);
  }

  public getNeedHelpHidden() {
    return localStorage.getItem(LocalStorageService.needHelpHidden);
  }

  public setGuestModeEnabled(value: string) {
    return localStorage.setItem(LocalStorageService.guestModeEnabled, value);
  }

  public getGuestModeEnabled() {
    return localStorage.getItem(LocalStorageService.guestModeEnabled) || false;
  }

  public setUserName(name: string) {
    return localStorage.setItem(LocalStorageService.userName, name);
  }

  public setPhoneNumber(number: string) {
    return localStorage.setItem(LocalStorageService.phoneNumber, number);
  }

  public getPhoneNumber() {
    return localStorage.getItem(LocalStorageService.phoneNumber);
  }

  public setInternational(value: boolean) {
    return localStorage.setItem(
      LocalStorageService.international,
      String(value)
    );
  }

  public getInternational(): boolean {
    return JSON.parse(
      localStorage.getItem(LocalStorageService.international) ?? 'false'
    );
  }

  public setEmailAddress(emailAddress: string) {
    return localStorage.setItem(LocalStorageService.emailAddress, emailAddress);
  }

  public getEmailAddress() {
    return localStorage.getItem(LocalStorageService.emailAddress);
  }

  public setInvitationDetails(invitationDetails: InvitationDetails) {
    localStorage.setItem(
      LocalStorageService.invitationDetails,
      JSON.stringify(invitationDetails)
    );
  }

  public getInvitationDetails(): InvitationDetails {
    const invitationDetails = localStorage.getItem(
      LocalStorageService.invitationDetails
    );
    return invitationDetails ? JSON.parse(invitationDetails) : {};
  }

  public setAuthFlowUser(user: User) {
    return localStorage.setItem(
      LocalStorageService.authFlowUser,
      JSON.stringify(user)
    );
  }

  public clearAuthFlowUser() { 
    localStorage.removeItem(LocalStorageService.authFlowUser);
  }

  public getAuthFlowUser(): CurrentUser {
    const user = localStorage.getItem(LocalStorageService.authFlowUser);
    return user ? JSON.parse(user) : null;
  }

  public setAuthFlowRedirectUrl(url: string) {
    return localStorage.setItem(LocalStorageService.authFlowRedirectUrl, url);
  }

  public getAuthFlowRedirectUrl() {
    return localStorage.getItem(LocalStorageService.authFlowRedirectUrl);
  }

  public clearAuthFlowRedirectUrl() {
    return localStorage.removeItem(LocalStorageService.authFlowRedirectUrl);
  }

  public clearAuthFlow() {
    localStorage.removeItem(LocalStorageService.invitationToken);
    localStorage.removeItem(LocalStorageService.emailAddress);
    localStorage.removeItem(LocalStorageService.phoneNumber);
    localStorage.removeItem(LocalStorageService.authFlowUser);
    localStorage.removeItem(LocalStorageService.authFlowStep);
    localStorage.removeItem(LocalStorageService.guestModeEnabled);
    localStorage.removeItem(LocalStorageService.international);
  }

  public setAuthFlowStep(step: AuthStep) {
    return localStorage.setItem(LocalStorageService.authFlowStep, step);
  }

  public getAuthFlowStep() {
    return localStorage.getItem(LocalStorageService.authFlowStep) as AuthStep;
  }

  public setDesktopOverride() {
    return localStorage.setItem(LocalStorageService.desktopOverride, 'true');
  }

  public getDesktopOverride() {
    return localStorage.getItem(LocalStorageService.desktopOverride);
  }

  public clear() {
    localStorage.clear();
  }

  public isAuthFlowStarted() {
    const authFlowUser = this.getAuthFlowUser();
    const authFlowStep = this.getAuthFlowStep();

    return authFlowUser !== null || authFlowStep !== null;
  }
}

export const localStorageService = new LocalStorageService();
