import { pushNotificationService } from '../PushNotificationService';
import { HttpService } from './HttpService';
import { isIOS } from 'react-device-detect';

export class NotificationApiService extends HttpService {
  private readonly subscribePath = '/notifications/subscribe';
  private readonly unsubscribePath = '/notifications/unsubscribe';
  private readonly clearNfeNotificationsPath =
    '/notifications/nfe/clear-notifications';
  private readonly clearWalletNotificationsPath =
    '/notifications/wallet/clear-notifications';

  public subscribe = async () => {
    this.abortPreviousRequest();
    const deviceToken = await pushNotificationService.getToken();
    await this.instance.post(this.subscribePath, { deviceToken, isIOS });
  };

  public unsubscribe = async () => {
    this.abortPreviousRequest();
    const deviceToken = await pushNotificationService.getToken();
    await this.instance.post(this.unsubscribePath, { deviceToken, isIOS });
  };

  public clearNfeNotifications = async () => {
    await this.instance.post(this.clearNfeNotificationsPath);
  };

  public clearWalletNotifications = async () => {
    await this.instance.post(this.clearWalletNotificationsPath);
  };
}

export const notificationApiService = new NotificationApiService();
