import { createTheme } from '@mui/material/styles';
import React from 'react';

export const DEFAULT_ERROR_COLOR = 'global.white';

declare module '@mui/material/styles' {
  interface Palette {
    global: {
      error: React.CSSProperties['color'];
      black: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      yellow: React.CSSProperties['color'];
      darkGrey: React.CSSProperties['color'];
      quartz: React.CSSProperties['color'];
      salmon: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      lightGrey: React.CSSProperties['color'];
      grey: React.CSSProperties['color'];
      turquoise: React.CSSProperties['color'];
      cherry: React.CSSProperties['color'];
      pink: React.CSSProperties['color'];
      red: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      lightGreen: React.CSSProperties['color'];
      greyBlueGradient: React.CSSProperties['color'];
      greyQuartzGradient: React.CSSProperties['color'];
      redPinkGradient: React.CSSProperties['color'];
      bluePinkGradient: React.CSSProperties['color'];
      purchaseCompleteGradient: React.CSSProperties['color'];
      blueTurquoiseGradient: React.CSSProperties['color'];
      radialGreenBlueGradient: React.CSSProperties['color'];
      radialCherryBlueGradient: React.CSSProperties['color'];
      radialCherryBlueCornerGradient: React.CSSProperties['color'];
      radialRedBlueGradient: React.CSSProperties['color'];
      pinkFadeGradient: React.CSSProperties['color'];
      radialPinkBlueGradient: React.CSSProperties['color'];
      radialGreenYellowGradient: React.CSSProperties['color'];
      radialLightGreenLightYellowGradient: React.CSSProperties['color'];
      blueYellowGradient: React.CSSProperties['color'];
    };
  }

  interface PaletteOptions {
    global: {
      error?: React.CSSProperties['color'];
      black?: React.CSSProperties['color'];
      blue?: React.CSSProperties['color'];
      yellow?: React.CSSProperties['color'];
      darkGrey?: React.CSSProperties['color'];
      quartz?: React.CSSProperties['color'];
      salmon?: React.CSSProperties['color'];
      white?: React.CSSProperties['color'];
      lightGrey?: React.CSSProperties['color'];
      grey?: React.CSSProperties['color'];
      turquoise?: React.CSSProperties['color'];
      cherry?: React.CSSProperties['color'];
      pink?: React.CSSProperties['color'];
      red?: React.CSSProperties['color'];
      green?: React.CSSProperties['color'];
      lightGreen?: React.CSSProperties['color'];
      greyBlueGradient?: React.CSSProperties['color'];
      greyQuartzGradient?: React.CSSProperties['color'];
      redPinkGradient?: React.CSSProperties['color'];
      bluePinkGradient?: React.CSSProperties['color'];
      purchaseCompleteGradient?: React.CSSProperties['color'];
      blueTurquoiseGradient?: React.CSSProperties['color'];
      radialGreenBlueGradient?: React.CSSProperties['color'];
      radialCherryBlueGradient?: React.CSSProperties['color'];
      radialCherryBlueCornerGradient?: React.CSSProperties['color'];
      radialRedBlueGradient?: React.CSSProperties['color'];
      pinkFadeGradient?: React.CSSProperties['color'];
      radialPinkBlueGradient?: React.CSSProperties['color'];
      radialGreenYellowGradient?: React.CSSProperties['color'];
      radialLightGreenLightYellowGradient?: React.CSSProperties['color'];
      blueYellowGradient?: React.CSSProperties['color'];
    };
  }

  interface TypographyVariants {
    bodyBold: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    descriptionBold: React.CSSProperties;
    descriptionMedium: React.CSSProperties;
    descriptionRegular: React.CSSProperties;
    label: React.CSSProperties;
    minBold: React.CSSProperties;
    minRegular: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyBold?: React.CSSProperties;
    bodyMedium?: React.CSSProperties;
    bodyRegular?: React.CSSProperties;
    descriptionBold?: React.CSSProperties;
    descriptionMedium?: React.CSSProperties;
    descriptionRegular?: React.CSSProperties;
    label?: React.CSSProperties;
    minBold?: React.CSSProperties;
    minRegular?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyBold: true;
    bodyMedium: true;
    bodyRegular: true;
    descriptionBold: true;
    descriptionMedium: true;
    descriptionRegular: true;
    label: true;
    minBold: true;
    minRegular: true;
  }
}

export default createTheme({
  palette: {
    global: {
      error: '#E0311A',
      black: '#00011F',
      blue: '#1215E2',
      yellow: '#DBFF00',
      darkGrey: '#1C1D2F',
      quartz: '#B0B2E1',
      salmon: '#FF6F5F',
      white: '#FFFFFF',
      lightGrey: '#E4E9EE',
      grey: '#505163',
      turquoise: '#56F0DE',
      cherry: '#FF2775',
      pink: '#F6C4FC',
      red: '#F6C4FC',
      green: '#30e3a5',
      lightGreen: '#19DD7F',
      greyBlueGradient:
        'linear-gradient(rgb(255 111 95 / 10%), rgb(18 21 226 / 100%))',
      greyQuartzGradient: 'linear-gradient(225deg, #D6D7EA 0%, #40415A 100%)',
      redPinkGradient: 'linear-gradient(225deg, #F9CCFF 0%, #D83F2D 100%)',
      bluePinkGradient: 'linear-gradient(225deg, #f9ccff 0%, #2e44ed 100%)',
      purchaseCompleteGradient:
        'linear-gradient(225deg, rgb(255 111 95 / 80%) 0%, #2e44ed 100%)',
      blueTurquoiseGradient:
        'linear-gradient(225deg, #72EBDC 0%, #4558EF 100%)',
      radialGreenBlueGradient:
        'radial-gradient(circle at 100% 90%,rgb(25 221 127 / 100%) 0%, rgb(25 221 127 / 100%) 15%, rgb(25 221 127 / 50%) 40%, rgb(18 21 226 / 100%) 100%)',
      radialRedBlueGradient:
        'radial-gradient(circle at 0% 50%, #1215e2 25%, #e0311a 100%);',
      radialCherryBlueGradient:
        'radial-gradient(circle at 0% 50%, #FF2775 25%,  #1215e2 100%);',
      radialCherryBlueCornerGradient:
        'radial-gradient(circle at -50% 130%, #FF2775 25%,  #1215e2 100%);',
      pinkFadeGradient: 'linear-gradient(270deg, #f92d78, #b381a4);',
      radialPinkBlueGradient:
        'radial-gradient(100px 400px at top right, #FF2775 0%, #1215E2 100%);',
      radialGreenYellowGradient:
        'radial-gradient(circle at 0% 15%, #19DD7F 0%, #DBFF00 100%);',
      radialLightGreenLightYellowGradient:
        'radial-gradient(circle at 0% 15%, #19DD7F 0%, #DBFF00 100%);',
      blueYellowGradient:
        'radial-gradient(circle at 130% -30%, #DBFF00 0%, transparent 100%), linear-gradient(225deg, #72EBDC 0%, #4558EF 100%)',
    },
  },
  typography: {
    h1: {
      fontFamily: '"Space Grotesk", sans-serif',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '80px',
      lineHeight: '90%',
      letterSpacing: '-1.5px',
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: '"Space Grotesk", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '40px',
      lineHeight: '110%',
      letterSpacing: '-2px',
    },
    h3: {
      fontFamily: '"Space Grotesk", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '32px',
      lineHeight: '110%',
      letterSpacing: '-1.5px',
    },
    h4: {
      fontFamily: '"Space Grotesk", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '130%',
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '140%',
    },
    bodyBold: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
    },
    bodyMedium: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '150%',
    },
    bodyRegular: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '150%',
    },
    descriptionBold: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '150%',
    },
    descriptionMedium: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '150%',
    },
    descriptionRegular: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '150%',
    },
    label: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '150%',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
    minBold: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '11px',
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
    minRegular: {
      fontFamily: '"Roboto", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
  },
});
