import {
  ReactNode,
  useState,
  createContext,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import useSWRImmutable from 'swr/immutable';
import {
  FeatureFlagApiService,
  featureFlagApiService,
} from '../services/api/FeatureFlagApiService';
import { usePageThrobber } from './usePageThrobber';

export enum FeatureFlagName {
  'TEAM_MANAGEMENT' = 'TEAM_MANAGEMENT',
  'STATION_MANAGEMENT' = 'STATION_MANAGEMENT',
  'PROMOTION_MANAGEMENT' = 'PROMOTION_MANAGEMENT',
  'MANAGER_INVITE' = 'MANAGER_INVITE',
  'BACKEND_API_SWITCHER' = 'BACKEND_API_SWITCHER',
  'USER_ROLE_SWITCHER' = 'USER_ROLE_SWITCHER',
  'SALES_DATA' = 'SALES_DATA',
  'TICKETS_SELL' = 'TICKETS_SELL',
  'TICKETS_TRANSFER' = 'TICKETS_TRANSFER',
  'EVENTS_ACTIVITY' = 'EVENTS_ACTIVITY',
  'USER_ACCOUNT' = 'USER_ACCOUNT',
  'OFFLINE' = 'OFFLINE',
  'REGISTRATION' = 'REGISTRATION',
  'FAQ' = 'FAQ',
  'WALLET' = 'WALLET',
  'MY_NFES' = 'MY_NFES',
  'SCAN_NFE' = 'SCAN_NFE',
  'PURCHASE_TICKET' = 'PURCHASE_TICKET',
  'GUEST_ROLE' = 'GUEST_ROLE',
  'FREE_TICKET' = 'FREE_TICKET',
  'GUEST_LISTS_PAGE' = 'GUEST_LISTS_PAGE',
  'MANAGER_HOME_PAGE_V2' = 'MANAGER_HOME_PAGE_V2'
}

export interface FeatureFlag {
  featureFlag: FeatureFlagName;
  enabled: boolean;
}

interface FeatureFlagContextType {
  [key: string]: () => boolean;
}

interface FeatureFlagProviderProps {
  children: ReactNode;
}

const FeatureFlagContext = createContext<FeatureFlagContextType>(
  {} as FeatureFlagContextType
);

export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const { data, error } = useSWRImmutable<FeatureFlag[]>(
    FeatureFlagApiService.getFeatureFlagsUrl,
    featureFlagApiService.getFeatureFlags
  );

  const { showThrobber, hideThrobber } = usePageThrobber();

  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isEnabled = useCallback(
    (feature: string) => {
      const flag = featureFlags.find((f) => f.featureFlag === feature);
      if (!flag) {
        return false;
      }

      return flag.enabled;
    },
    [featureFlags]
  );

  const isTeamManagementFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.TEAM_MANAGEMENT),
    [isEnabled]
  );

  const isStationManagementFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.STATION_MANAGEMENT),
    [isEnabled]
  );

  const isPromotionManagementFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.PROMOTION_MANAGEMENT),
    [isEnabled]
  );

  const isManagerInviteFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.MANAGER_INVITE),
    [isEnabled]
  );

  const isBackendApiSwitcherFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.BACKEND_API_SWITCHER),
    [isEnabled]
  );

  const isSalesDataFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.SALES_DATA),
    [isEnabled]
  );

  const isTicketsSellFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.TICKETS_SELL),
    [isEnabled]
  );

  const isTicketsTransferFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.TICKETS_TRANSFER),
    [isEnabled]
  );

  const isEventActivityFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.EVENTS_ACTIVITY),
    [isEnabled]
  );

  const isUserAccountFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.USER_ACCOUNT),
    [isEnabled]
  );

  const isOfflineFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.OFFLINE),
    [isEnabled]
  );
  
  const isRegistrationFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.REGISTRATION),
    [isEnabled]
  );

  const isFaqFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.FAQ),
    [isEnabled]
  );

  const isWalletFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.WALLET),
    [isEnabled]
  );

  const isMyNfesFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.MY_NFES),
    [isEnabled]
  );

  const isScanNfeFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.SCAN_NFE),
    [isEnabled]
  );

  const isPurchaseTicketFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.PURCHASE_TICKET),
    [isEnabled]
  );

  const isGuestRoleFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.GUEST_ROLE),
    [isEnabled]
  );

  const isFreeTicketsFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.FREE_TICKET),
    [isEnabled]
  );

  const isGuestListsPageFlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.GUEST_LISTS_PAGE),
    [isEnabled]
  );

  const isManagerHomePageV2FlagEnabled = useCallback(
    () => isEnabled(FeatureFlagName.MANAGER_HOME_PAGE_V2),
    [isEnabled]
  );

  const memoizedValues = useMemo(() => {
    return {
      isTeamManagementFlagEnabled,
      isStationManagementFlagEnabled,
      isPromotionManagementFlagEnabled,
      isManagerInviteFlagEnabled,
      isBackendApiSwitcherFlagEnabled,
      isSalesDataFlagEnabled,
      isTicketsSellFlagEnabled,
      isTicketsTransferFlagEnabled,
      isEventActivityFlagEnabled,
      isUserAccountFlagEnabled,
      isOfflineFlagEnabled,
      isRegistrationFlagEnabled,
      isFaqFlagEnabled,
      isWalletFlagEnabled,
      isMyNfesFlagEnabled,
      isScanNfeFlagEnabled,
      isPurchaseTicketFlagEnabled,
      isGuestRoleFlagEnabled,
      isFreeTicketsFlagEnabled,
      isGuestListsPageFlagEnabled,
      isManagerHomePageV2FlagEnabled
    };
  }, [
    isTeamManagementFlagEnabled,
    isStationManagementFlagEnabled,
    isPromotionManagementFlagEnabled,
    isManagerInviteFlagEnabled,
    isBackendApiSwitcherFlagEnabled,
    isSalesDataFlagEnabled,
    isTicketsSellFlagEnabled,
    isTicketsTransferFlagEnabled,
    isEventActivityFlagEnabled,
    isUserAccountFlagEnabled,
    isOfflineFlagEnabled,
    isRegistrationFlagEnabled,
    isFaqFlagEnabled,
    isWalletFlagEnabled,
    isMyNfesFlagEnabled,
    isScanNfeFlagEnabled,
    isPurchaseTicketFlagEnabled,
    isGuestRoleFlagEnabled,
    isFreeTicketsFlagEnabled,
    isGuestListsPageFlagEnabled,
    isManagerHomePageV2FlagEnabled
  ]);

  useEffect(() => {
    showThrobber();
    if (data && !error) {
      setFeatureFlags(data);
      setIsLoading(false);
      hideThrobber();
    }
  }, [data, error, hideThrobber, showThrobber]);

  return (
    <FeatureFlagContext.Provider value={memoizedValues}>
      {isLoading ? null : children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagContext);
