import { Divider, Grid, SxProps, Typography, useTheme } from '@mui/material';
import { AppHeader } from '../AppHeader';
import { TknPageContainer } from '../TknPageContainer';
import { useTranslation } from 'next-i18next';
import { TknButton } from '../TknButton';
import { calculateiOSInset } from '../../utils/tkn-util';
import { OfflineLogo } from './OfflineLogo';

interface ConnectionLostScreenStyle {
  divider: SxProps;
}

const style: ConnectionLostScreenStyle = {
  divider: {
    bgcolor: 'global.white',
    opacity: 0.1,
    mt: 1,
    mb: 6.25,
  },
};

export const ConnectionLostScreen = () => {
  const theme = useTheme();
  // Default values are needed if for some reason
  // the translations are not yet loaded.
  const { t } = useTranslation();

  return (
    <TknPageContainer
      textColor={theme.palette.global.white}
      background={theme.palette.global.radialGreenBlueGradient}
    >
      <Grid container direction="column" gap={5}>
        <Grid item marginBottom={calculateiOSInset(96)}>
          <AppHeader
            backgroundColor="transparent"
            leftChild={<OfflineLogo />}
          />
        </Grid>
        <Grid item>
          <Typography variant="h3" marginBottom={2} id="title">
            {t('internetConnection.title', {
              ns: 'error',
              defaultValue: 'Internet connection lost',
            })}
          </Typography>
          <Typography variant="bodyRegular" id="description">
            {t('internetConnection.description', {
              ns: 'error',
              defaultValue: 'Check your internet connection and try again',
            })}
          </Typography>
        </Grid>
        <Divider sx={style.divider} />
        <Grid item marginBottom={3} width={'90%'} alignSelf="center">
          <TknButton
            label={t('tryAgain', { ns: 'common', defaultValue: 'Try again' })}
            variant="outlined"
            styleVariant="white"
            size="large"
          />
        </Grid>
      </Grid>
    </TknPageContainer>
  );
};
