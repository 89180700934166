import { useTheme } from '@mui/material';
import {
  alpha,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  SxProps,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { RuleValidationProps } from './common/RulesValidationProps';

interface RadioButtonOptionProps {
  label: string | React.ReactElement;
  id: string;
}

type RadioButtonsLayoutType = 'grid' | 'column';

interface TknRadioButtonsProps {
  id: string;
  control: Control<any, object>;
  rules?: RuleValidationProps;
  radioButtonsOptions: RadioButtonOptionProps[];
  layout: RadioButtonsLayoutType;
  color?: string;
  background?: string;
  checkedColor?: string;
  checkedBackground?: string;
  disabled?: boolean;
}

interface TknRadioButtonProps {
  id: string;
  label: string | React.ReactElement;
  value: string;
  layout: RadioButtonsLayoutType;
  color: string;
  background: string;
  checkedColor: string;
  checkedBackground: string;
  disabled?: boolean;
}

interface TknRadioButtonsStyle {
  label: (
    layout: RadioButtonsLayoutType,
    color: string,
    background: string
  ) => SxProps;
  wrapper: SxProps;
}

const BpIcon = styled('span')<{ layout: RadioButtonsLayoutType }>(
  ({ layout }) => {
    const size = layout === 'grid' ? 16 : 22;
    return {
      borderRadius: '50%',
      width: size,
      height: size,
      backgroundColor: 'transparent',
      border: '1px solid rgba(255, 255, 255, 0.6)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  }
);

const BpCheckedIcon = styled(BpIcon)(({ theme, layout }) => {
  if (layout === 'column') {
    return {
      border: `1px solid ${theme.palette.global.yellow}`,
      '&:before': {
        display: 'block',
        width: 8,
        height: 8,
        borderRadius: '50%',
        content: '""',
        background: theme.palette.global.yellow,
      },
    };
  }
  return {
    border: `1px solid ${theme.palette.global.black}`,
    '&:before': {
      display: 'block',
      width: 6,
      height: 6,
      borderRadius: '50%',
      content: '""',
      background: theme.palette.global.black,
    },
  };
});

const labelStyle = {
  fontSize: '12px',
  lineHeight: '18px',
  padding: '0 12px 0 0',
};

const style: TknRadioButtonsStyle = {
  label: (layout, color, background) => {
    if (layout === 'grid') {
      return {
        '&.MuiFormControlLabel-root': {
          borderRadius: '100px',
          border: '1px solid rgba(255, 255, 255, 0.15)',
          color,
          backgroundColor: background,
        },
        '& .MuiFormControlLabel-label': {
          ...labelStyle,
        },
        '.MuiFormControlLabel-label.Mui-disabled': {
          color,
          ...labelStyle,
        },
      };
    }
    return {
      '&.MuiFormControlLabel-root': {},
      '& .MuiFormControlLabel-label': {
        ...labelStyle,
      },
      '.MuiFormControlLabel-label.Mui-disabled': {
        color,
        ...labelStyle,
      },
    };
  },
  wrapper: {
    width: '100%',
  },
};

const TknRadioButton = ({
  id,
  label,
  value,
  color,
  layout,
  checkedColor,
  background,
  checkedBackground,
  disabled,
}: TknRadioButtonProps) => {
  return (
    <FormControlLabel
      id={id}
      label={label}
      value={id}
      control={
        <Radio
          icon={<BpIcon layout={layout} />}
          checkedIcon={<BpCheckedIcon layout={layout} />}
          disabled={disabled}
        />
      }
      sx={
        value === id
          ? style.label(layout, checkedColor, checkedBackground)
          : style.label(layout, color, background)
      }
    />
  );
};

export const TknRadioButtons = (props: TknRadioButtonsProps) => {
  const theme = useTheme();
  const color = props.color ?? (theme.palette.global.white as string);
  const checkedColor =
    props.checkedColor ?? (theme.palette.global.black as string);
  const background =
    props.background ?? alpha(theme.palette.global.black as string, 0.3);
  const checkedBackground =
    props.checkedBackground ?? (theme.palette.global.yellow as string);
  const flexDirection = props.layout === 'column' ? 'column' : 'row';
  return (
    <Controller
      name={props.id}
      control={props.control}
      rules={props.rules}
      render={({ field: { onChange, value } }) => (
        <RadioGroup value={value} onChange={onChange} sx={style.wrapper}>
          <Grid container direction={flexDirection} justifyContent="center">
            {props.radioButtonsOptions.map((radio) => {
              return (
                <Grid item key={radio.id}>
                  <TknRadioButton
                    id={radio.id}
                    label={radio.label}
                    value={value}
                    layout={props.layout}
                    color={color}
                    checkedColor={checkedColor}
                    background={background}
                    checkedBackground={checkedBackground}
                    disabled={props.disabled}
                  />
                </Grid>
              );
            })}
          </Grid>
        </RadioGroup>
      )}
    ></Controller>
  );
};
