import { AppBar, Grid, SxProps, useTheme, Theme } from '@mui/material';

export interface AppHeaderProps {
  leftChild?: React.ReactNode;
  middleChild?: React.ReactNode;
  rightChild?: React.ReactNode;
  backgroundColor?: string;
  background?: string;
  textColor?: string;
  boxShadow?: string;
  padding?: string;
}

interface AppHeaderStyle {
  appBar: (props: AppHeaderProps, theme: Theme) => SxProps;
  middleChild: SxProps;
}

const style: AppHeaderStyle = {
  appBar: (props: AppHeaderProps, theme: Theme) => ({
    color: props.textColor ?? theme.palette.global.white,
    // `bgcolor` supports values like global.black while `background` does not.
    // If we don't supply `backgroundColor` nor the `background` prop
    // it will fall back to transparent.
    bgcolor: props.backgroundColor,
    background:
      props.background ?? props.backgroundColor ?? theme.palette.global.blue,
    boxShadow: props.boxShadow ?? 'none',
    padding: props.padding ?? '10px 16px',
    height: 'calc(56px + env(safe-area-inset-top))',
    top: 0,
    justifyContent: 'end',
  }),
  middleChild: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

export function AppHeader(props: AppHeaderProps) {
  const theme = useTheme();

  return (
    <AppBar sx={style.appBar(props, theme)}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        <Grid item>{props.leftChild}</Grid>
        <Grid item sx={style.middleChild}>
          {props.middleChild}
        </Grid>
        <Grid item>{props.rightChild}</Grid>
      </Grid>
    </AppBar>
  );
}
