import jwtDecode from 'jwt-decode';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { localStorageService } from '../services/LocalStorageService';
import { notificationApiService } from '../services/api/NotificationApiService';
import { DecodedToken } from '../types/auth/Tokens';
import { TokenUserRole } from '../types/enums/TokenUserRole';
import { Fan, Staff, Manager, Guest } from '../types/User';
import { useRouter } from 'next/router';

interface AuthContextType {
  user: CurrentUser;
  isUserLoggedIn: boolean;
  setUser: (user: CurrentUser) => void;
  setIsUserLoggedIn: (value: boolean) => void;
  enableGuestMode: () => void;
  logout: () => Promise<void>;
}

export type CurrentUser = Fan | Manager | Staff | Guest | null;

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<CurrentUser>(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const router = useRouter();

  const logout = useCallback(async () => {
    if (!localStorageService.isAuthFlowStarted()) {
      if (user !== null) {
        await notificationApiService.unsubscribe();
      }
      
      if(localStorageService.getNeedHelpHidden()) {
        localStorageService.clear();
        localStorageService.setNeedHelpHidden('yes');
      } else {
        localStorageService.clear();
      }
    }
  }, [user]);

  const enableGuestMode = useCallback(() => {
    setIsUserLoggedIn(true);
    setUser({ role: TokenUserRole.GUEST } as CurrentUser);
    localStorageService.setGuestModeEnabled('true');
  }, []);

  useEffect(() => {
    if (!isUserLoggedIn || localStorageService.getGuestModeEnabled()) {
      return;
    }

    const jwtToken = localStorageService.getBearerToken();
    if (!jwtToken) {
      return;
    }
    const { exp }: DecodedToken = jwtDecode(jwtToken);

    const logoutTimer = setTimeout(() => {
      if (isUserLoggedIn && user) {
        if (!['/', '/logout'].includes(router.asPath)) {
          router.push('/logout');
        }
      }
    }, exp * 1000 - Date.now());
    return () => clearTimeout(logoutTimer);
  }, [isUserLoggedIn, logout, router, user]);

  const memoizedValues = useMemo(
    () => ({
      user,
      isUserLoggedIn,
      setIsUserLoggedIn,
      setUser,
      logout,
      enableGuestMode,
    }),
    [user, isUserLoggedIn, logout, enableGuestMode]
  );

  return (
    <AuthContext.Provider value={memoizedValues}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
