import { Grid, Box, SxProps, useTheme } from '@mui/material';
import Image from 'next/image';
import { TknDialogProps, TknModalDialog } from '../TknModalDialog';
import { isPreoptimizedSrc } from '../../../utils/tkn-util';

interface TknDialogWithImageProps extends TknDialogProps {
  imageSrc: string;
  imageOverlayColor?: string;
}

interface TknDialogWithImageStyles {
  imageWrapper: (overlayColor: string) => SxProps;
  modalContainer: SxProps;
}

const styles: TknDialogWithImageStyles = {
  imageWrapper: (overlayColor) => ({
    '& img': {
      mixBlendMode: 'overlay',
    },
    width: '100%',
    height: '140px',
    position: 'absolute',
    top: 0,
    left: 0,
    background: overlayColor,
  }),
  modalContainer: {
    paddingTop: '140px',
  },
};

export const TknDialogWithImage = ({
  children,
  id,
  variant,
  actions,
  open,
  imageSrc,
  imageOverlayColor,
}: TknDialogWithImageProps) => {
  const theme = useTheme();
  return (
    <TknModalDialog id={id} variant={variant} actions={actions} open={open}>
      <Grid container direction="column" sx={styles.modalContainer}>
        <Box
          sx={styles.imageWrapper(
            imageOverlayColor ||
              (theme.palette.global.blueTurquoiseGradient as string)
          )}
        >
          <Image
            src={imageSrc}
            objectFit="cover"
            layout="fill"
            alt="nfe-event-image"
            {...(isPreoptimizedSrc(imageSrc) && { unoptimized: true })}
          />
        </Box>
        {children}
      </Grid>
    </TknModalDialog>
  );
};
