import {
  Card,
  CardContent,
  Dialog,
  Divider,
  SxProps,
  Typography,
} from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

export enum TknModalDialogActionResult {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface TknDialogProps {
  id: string;
  open: boolean;
  variant: 'light' | 'dark';
  actions: TknDialogAction[];
  children?: ReactNode;
}

interface TknDialogAction {
  label: string;
  action: () => void;
  disabled?: boolean;
}

interface TknModalDialogStyle {
  card: (variant: string) => SxProps;
  paper: SxProps;
  content: SxProps;
  divider: SxProps;
  action: (disabled: boolean) => CSSProperties;
}

const style: TknModalDialogStyle = {
  card: (variant: string) => ({
    paddingTop: '20px',
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: variant === 'dark' ? 'global.black' : 'global.darkGrey',
    color: 'global.white',
  }),
  paper: {
    borderRadius: '32px',
    padding: 0,
    width: '100%',
  },
  content: {
    width: '100%',
    p: 0,
    textAlign: 'center',
  },
  divider: {
    marginTop: '12px',
    marginBottom: '14px',
    width: '100%',
    borderColor: 'global.white',
    opacity: 0.1,
    borderWidth: '1px',
  },
  action: (disabled: boolean) => ({
    cursor: 'pointer',
    ...(disabled && {
      opacity: 0.5,
    }),
  }),
};

export const TknModalDialog: React.FC<TknDialogProps> = (props) => {
  return (
    <Dialog open={props.open} PaperProps={{ sx: style.paper }} id={props.id}>
      <Card sx={style.card(props.variant)}>
        <CardContent sx={style.content}>
          {props.children}
          {props.actions.map((action, idx) => {
            return (
              <div
                key={action.label}
                onClick={() => {
                  if (!action.disabled) {
                    action.action();
                  }
                }}
                style={style.action(action.disabled ?? false)}
                id={`${props.id}-action-${idx}`}
              >
                <Divider sx={style.divider} />
                <Typography variant="bodyRegular">{action.label}</Typography>
              </div>
            );
          })}
        </CardContent>
      </Card>
    </Dialog>
  );
};
