import Head from 'next/head';
import '../styles/globals.css';
import { AppProps } from 'next/app';
import '@fontsource/space-grotesk';
import '@fontsource/roboto';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import theme from '../styles/themes/tkn-mvp.theme';
import { SWRConfig } from 'swr';
import Script from 'next/script';
import { AuthProvider } from '../hooks/useAuth';
import { RouteGuard } from '../components/auth/RouteGuard';
import { RestoreSession } from '../components/auth/RestoreSession';
import { PushNotificationProvider } from '../hooks/usePushNotifications';
import { PushNotificationContainer } from '../components/PushNotificationContainer';
import { MXProvider } from '../hooks/useMX';
import { PreRenderContainer } from '../hooks/usePreRendering';
import * as Sentry from '@sentry/nextjs';
import ClosedApplication from './_closed';
import OfflinePage from './_offline';
import { ReactNode, useMemo } from 'react';
import { useNetworkState } from 'react-use';
import { AnimatePresence } from 'framer-motion';
import { useFeatureFlags, FeatureFlagProvider } from '../hooks/useFeatureFlags';
import { PageThrobberProvider } from '../hooks/usePageThrobber';

interface BodyProps {
  children: ReactNode;
  pageProps: any;
}

const Body = ({ children, pageProps }: BodyProps) => {
  // todo really need to re-evaluate this scenario
  // while this needs to be updated to do offline development, 
  // it also needs to be updated for user experience. 
  const { online } = useNetworkState();
  const { isRegistrationFlagEnabled, isOfflineFlagEnabled } = useFeatureFlags();

  if (online || isOfflineFlagEnabled()) {
    return isRegistrationFlagEnabled() ? (
      <>{children}</>
    ) : (
      <ClosedApplication {...pageProps} />
    );
  }
  if (!online && online !== undefined) {
    return <OfflinePage />;
  }

  return null;
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  const body = useMemo(
    () => (
      <PageThrobberProvider>
        <SWRConfig
          value={{
            onError: (error) => {
              Sentry.captureException(error);
            },
            revalidateOnFocus: false, // Prevent page reloads when switching tabs, clicking on selects, etc.
            revalidateOnReconnect: false, // Prevent page reloads when reconnecting to the internet
          }}
        >
          <FeatureFlagProvider>
            <Body pageProps={pageProps}>
              <AuthProvider>
                <RestoreSession>
                  <RouteGuard>
                    <PushNotificationProvider>
                      <MXProvider>
                        <PushNotificationContainer />
                        <Component {...pageProps} />
                      </MXProvider>
                    </PushNotificationProvider>
                  </RouteGuard>
                </RestoreSession>
              </AuthProvider>
            </Body>
          </FeatureFlagProvider>
        </SWRConfig>
      </PageThrobberProvider>
    ),
    [pageProps, Component]
  );

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"
        />
        <meta name="apple-mobile-web-app-capable" content="yes"></meta>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        ></meta>
        <meta name="description" content="Description" />
        <meta name="keywords" content="Keywords" />
        <title>Token Events</title>

        <link rel="manifest" href="/manifest.json" />
        <link href="/manifest_icon.svg" rel="icon" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/manifest_icon.png"></link>
        <meta name="theme-color" content="#1215E2" />
      </Head>
      <Script src="https://unpkg.com/share-api-polyfill@1.1.0/dist/share-min.js" />
      <Script src="https://atrium.mx.com/connect.js" />
      <Script
        src="https://static.soraid.com/sora.js"
        strategy="beforeInteractive"
      />

      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AnimatePresence>
            <PreRenderContainer>{body}</PreRenderContainer>
          </AnimatePresence>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
};

export default appWithTranslation(MyApp);
