import { omit } from 'lodash';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { ReactNode, useCallback, useEffect, useState, useMemo } from 'react';
import { localStorageService } from '../../services/LocalStorageService';
import { TknBackendApiOption } from '../../types/enums/TknBackendApiOption';
import { PUBLIC_ROUTES, ROUTES_BY_USER_ROLE } from '../../types/Routes';
import { getHomepagePath, isGuest } from '../../utils/tkn-user-util';
import { isEmptyArray } from '../../utils/tkn-util';
import useCurrentUser from '../../hooks/useCurrentUser';
import { TokenUserRole } from '../../types/enums/TokenUserRole';
import { useAuth } from '../../hooks/useAuth';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { usePageThrobber } from '../../hooks/usePageThrobber';

interface RouteGuardProps {
  children: ReactNode;
}

export const RouteGuard = ({ children }: RouteGuardProps) => {
  const { showThrobber, hideThrobber } = usePageThrobber();
  const router = useRouter();
  const [showChildren, setShowChildren] = useState(false);
  const user = useCurrentUser();
  const { enableGuestMode, isUserLoggedIn } = useAuth();
  const currentRole = user?.role as TokenUserRole;
  const {
    isBackendApiSwitcherFlagEnabled,
    isManagerInviteFlagEnabled,
    isPromotionManagementFlagEnabled,
    isStationManagementFlagEnabled,
    isTeamManagementFlagEnabled,
    isSalesDataFlagEnabled,
    isTicketsSellFlagEnabled,
    isTicketsTransferFlagEnabled,
    isUserAccountFlagEnabled,
    isFaqFlagEnabled,
    isWalletFlagEnabled,
    isMyNfesFlagEnabled,
    isScanNfeFlagEnabled,
    isGuestListsPageFlagEnabled,
  } = useFeatureFlags();

  const featureFlagRoutes = useMemo(
    () => [
      [/^\/backend\-api\-switcher$/, isBackendApiSwitcherFlagEnabled],
      [/^\/faq$/, isFaqFlagEnabled],
      [/^\/profile](\/.*)?$/, isUserAccountFlagEnabled],
      [/^\/events\/(.*)\/sales-activity(\/.*)?$/, isSalesDataFlagEnabled],
      [/^\/manager\/scan\-nfe$/, isScanNfeFlagEnabled],
      [/^\/manager\/team$/, isTeamManagementFlagEnabled],
      [/^\/manager\/promotion(\/.*)?$/, isPromotionManagementFlagEnabled],
      [/^\/manager\-invite$/, isManagerInviteFlagEnabled],
      [/^\/(staff|manager)\/stations(\/.+)?$/, isStationManagementFlagEnabled],
      [
        /^\/manager\/(stations|products)\/.+\/sales\-activity$/,
        isSalesDataFlagEnabled,
      ],
      [/^\/events\/.+\/transfer-nfe$/, isTicketsTransferFlagEnabled],
      [/^\/events\/.+\/sell-nfe$/, isTicketsSellFlagEnabled],
      [/^\/wallet$/, isWalletFlagEnabled],
      [/^\/my-nfes$/, isMyNfesFlagEnabled],
      [/^\/manager\/guest-list(\/.+)?$/, isGuestListsPageFlagEnabled],
    ],
    [
      isBackendApiSwitcherFlagEnabled,
      isFaqFlagEnabled,
      isUserAccountFlagEnabled,
      isSalesDataFlagEnabled,
      isScanNfeFlagEnabled,
      isTeamManagementFlagEnabled,
      isPromotionManagementFlagEnabled,
      isManagerInviteFlagEnabled,
      isStationManagementFlagEnabled,
      isTicketsTransferFlagEnabled,
      isTicketsSellFlagEnabled,
      isWalletFlagEnabled,
      isMyNfesFlagEnabled,
      isGuestListsPageFlagEnabled,
    ]
  );

  const isUserAllowedToAccessTheRoute = useCallback(
    (path: string, query: ParsedUrlQuery): boolean => {
      const accessibleRoutes = currentRole
        ? ROUTES_BY_USER_ROLE[currentRole]
        : PUBLIC_ROUTES;

      const trimmedQuery = omit(query, 'pk');
      const pathParameters = Object.values(trimmedQuery);

      // Go through featureFlagRoutes and check if the current route is allowed.
      // If the route doesn't match any of the featureFlagRoutes, then it's allowed.
      for (const [regex, isEnabled] of featureFlagRoutes) {
        if (regex instanceof RegExp && regex.test(path)) {
          if (isEnabled instanceof Function && !isEnabled()) {
            return false;
          }
        }
      }
      
      return accessibleRoutes.some((routeFn) =>
        !isEmptyArray(pathParameters)
          ? path === routeFn(...(pathParameters as string[]))
          : path === routeFn()
      );
    },
    [currentRole, featureFlagRoutes]
  );

  const checkAuthorization = useCallback(
    (url: string, query: ParsedUrlQuery) => {
      if (query.hasOwnProperty('shallow')) {
        return;
      }
      const path = url.split('?')[0];
      const backend = localStorageService.getBackendApiOption();
      if (
        [
          '/',
          '/create-account',
          '/confirm-code',
          '/login',
          '/register',
        ].includes(path) &&
        isUserLoggedIn &&
        !isGuest(user)
      ) {
        setShowChildren(false);
        if (user) {
          const homepagePath = getHomepagePath(user.role);
          if (user.role !== TokenUserRole.FAN) {
            router.push(homepagePath);
            return;
          }

          const redirectUrl = localStorageService.getAuthFlowRedirectUrl();
          localStorageService.clearAuthFlowRedirectUrl();
          router.push(redirectUrl || homepagePath);
          return;
        }
        router.push('/register');
      }

      if (backend === TknBackendApiOption.REAL) {
        // handle mid registration flows
        if (path === '/register') {
          if (user && currentRole && !isGuest(user)) {
            setShowChildren(false);
            router.push(getHomepagePath(currentRole));
            return;
          }
          if (localStorageService.isAuthFlowStarted()) {
            setShowChildren(true);
            return;
          } else {
            setShowChildren(false);
            router.push('/');
            return;
          }
        }
        // redirect registered users away from guest
        if (path.startsWith('/guest') && !isGuest(user)) {
          setShowChildren(false);
          router.push(getHomepagePath(user?.role));
          return;
        }
        // if there is no user, allow deeplink to public routes
        if (!user) {
          // allow unregistered guests to deeplink
          const guestRoutes = ROUTES_BY_USER_ROLE[TokenUserRole.GUEST].map(
            (routeFn) => routeFn()
          );
          if (guestRoutes.includes(router.route)) {
            setShowChildren(false);
            enableGuestMode();
            return;
          }
          // handle all other public routes
          const publicRoutes = PUBLIC_ROUTES.map((routeFn) => routeFn());
          if (!publicRoutes.includes(path)) {
            setShowChildren(false);
            router.push('/');
            return;
          }
        }
      }
      // generic role specific routes
      if (!isUserAllowedToAccessTheRoute(path, query)) {
        setShowChildren(false);
        router.push('/404');
      } else {
        setShowChildren(true);
      }
    },
    [
      currentRole,
      enableGuestMode,
      isUserAllowedToAccessTheRoute,
      isUserLoggedIn,
      router,
      user,
    ]
  );

  const onRouteChangeStarted = useCallback(
    () => showThrobber(),
    [showThrobber]
  );
  const onRouteChangeComplete = useCallback(
    () => hideThrobber(),
    [hideThrobber]
  );

  useEffect(() => {
    checkAuthorization(router.asPath, router.query);
    router.events.on('routeChangeStart', checkAuthorization);
    router.events.on('routeChangeStart', onRouteChangeStarted);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    router.events.on('routeChangeError', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeStart', checkAuthorization);
      router.events.off('routeChangeStart', onRouteChangeStarted);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      router.events.off('routeChangeError', onRouteChangeComplete);
    };
  }, [
    checkAuthorization,
    router.asPath,
    router.events,
    router.query,
    user,
    onRouteChangeStarted,
    onRouteChangeComplete,
  ]);

  if (showChildren) {
    return <>{children}</>;
  }
  return null;
};
