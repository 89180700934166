import { FeatureFlag } from '../../hooks/useFeatureFlags';
import { HttpService } from './HttpService';

export class FeatureFlagApiService extends HttpService {
  public static readonly getFeatureFlagsUrl = '/featureFlags';

  public getFeatureFlags = async (path: string) => {
    return this.instance.get<FeatureFlag[]>(path);
  };
}

export const featureFlagApiService = new FeatureFlagApiService();
