import { Breakpoint, Container, SxProps, Theme, useTheme } from '@mui/material';
import Head from 'next/head';
import React from 'react';

interface TknPageContainerProps {
  className?: string;
  background?: string;
  maxWidth?: Breakpoint;
  children?: React.ReactNode;
  textColor?: string;
  position?: string;
  bodyColor?: string;
  isFlex?: boolean;
}

interface TknPageContainerStyle {
  container: (props: TknPageContainerProps, theme: Theme) => SxProps;
}

const style: TknPageContainerStyle = {
  container: (props, theme) => ({
    maxWidth: props.maxWidth ?? 'lg',
    color: props.textColor ?? 'global.white',
    // TODO: Remove this when we have properly
    // switched to the theme system.
    ...(!props.className && {
      background: props.background ?? theme.palette.global.blue,
    }),
    ...(props.isFlex && {
      display: 'flex',
      flexDirection: 'column',
    }),
    overflowX: 'hidden',
    backgroundSize: 'cover',
    minHeight:  '100vh',
    paddingBottom: 'env(safe-area-inset-bottom))',
    position: props.position ?? '',
  }),
};

export const TknPageContainer = (props: TknPageContainerProps) => {
  const theme = useTheme();

  return (
    <Container className={props.className} sx={style.container(props, theme)}>
      {props.children}
      {props.bodyColor && (
        <Head>
          <style>
            {`body {
              background: ${props.bodyColor} !important;
            }`}
          </style>
          <meta name="theme-color" content={props.bodyColor} />
        </Head>
      )}
    </Container>
  );
};
