import { usePushNotifications } from '../hooks/usePushNotifications';
import {
  PushNotificationPayload,
  PushNotificationType,
} from '../types/PushNotificationPayload';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { BroadcastNotification } from './notifications/BroadcastNotification';
import { PromotionAnnouncement } from './notifications/PromotionAnnouncement';
import { SnackbarWithIconNotification } from './snackbars/SnackbarWithIconNotification';
import LanguageIcon from '@mui/icons-material/Language';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuth } from '../hooks/useAuth';
import useCurrentUser from '../hooks/useCurrentUser';
import { Fan } from '../types/User';
import { useSWRConfig } from 'swr';
import { nftApiService } from '../services/api/NftApiService';
import { isGuest } from '../utils/tkn-user-util';
import { EngagementQuestion } from './notifications/EngagementQuestion';

export const PushNotificationContainer = () => {
  const { messages, clearNotificationById } = usePushNotifications();
  const router = useRouter();
  const { user, setUser, isUserLoggedIn } = useAuth();
  const fan = useCurrentUser<Fan>();
  const { mutate } = useSWRConfig();

  const createNotification = useCallback(
    (message: PushNotificationPayload) => {
      switch (message.type) {
        case PushNotificationType.ENGAGEMENT_QUESTION:
          return (
            <EngagementQuestion
              key={message.id}
              message={message}
              onClose={() => clearNotificationById(message.id)}
            />
          );
        case PushNotificationType.PROMOTION:
          return (
            <PromotionAnnouncement
              key={message.id}
              message={message}
              onClose={() => clearNotificationById(message.id)}
            />
          );
        case PushNotificationType.BROADCAST:
          return (
            <BroadcastNotification
              key={message.id}
              message={message}
              onClose={() => clearNotificationById(message.id)}
            />
          );
        case PushNotificationType.NFT_MINT_STARTED:
          return (
            <SnackbarWithIconNotification
              key={message.id}
              message={message}
              icon={<LanguageIcon />}
              variant="black"
              iconColor="global.yellow"
              onCloseIconClick={async () => {
                clearNotificationById(message.id);
                await mutate(nftApiService.getWalletPath);
                router.push('/wallet');
              }}
            />
          );
        case PushNotificationType.NFT_MINTED:
          // We cannot set the state in render,
          // that's why we use a callback here.
          setTimeout(() => {
            const { data } = message;
            const { unseenWalletNotifications } = JSON.parse(data);
            setUser({
              ...fan,
              unseenWalletNotifications,
            });
          }, 0);

          return (
            <SnackbarWithIconNotification
              key={message.id}
              message={message}
              icon={<CheckCircleOutlineOutlinedIcon />}
              variant="black"
              iconColor="global.green"
              onCloseIconClick={() => {
                clearNotificationById(message.id);
              }}
            />
          );

        case PushNotificationType.SCAN_SUCCESS:
          return (
            <SnackbarWithIconNotification
              key={message.id}
              message={message}
              icon={<CheckCircleOutlineOutlinedIcon />}
              variant="black"
              iconColor="global.green"
              onCloseIconClick={() => clearNotificationById(message.id)}
            />
          );
        case PushNotificationType.NFT_ERROR:
          return (
            <SnackbarWithIconNotification
              key={message.id}
              message={message}
              icon={<CancelIcon />}
              variant="black"
              iconColor="global.error"
              onCloseIconClick={() => clearNotificationById(message.id)}
            />
          );
        case PushNotificationType.NFE_MINTED:
          // We cannot set the state in render,
          // that's why we use a callback here.
          setTimeout(() => {
            const { data } = message;
            const { unseenMyNfeNotifications } = JSON.parse(data);
            setUser({
              ...fan,
              unseenMyNfeNotifications,
            });
            clearNotificationById(message.id);
          }, 0);
          return null;

        default:
          console.warn('Unknown notification type', message.type);
          console.warn('Payload:', message);
          return null;
      }
    },
    [clearNotificationById, router, fan, setUser, mutate]
  );

  if (!isUserLoggedIn || isGuest(user)) {
    return null;
  }

  return <>{messages.map(createNotification)}</>;
};
