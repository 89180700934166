import { ConnectionLostScreen } from '../components/offline/ConnectionLostScreen';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { motion } from 'framer-motion';

const Offline = () => {
  return (
    <motion.div
      key="offline-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ConnectionLostScreen />
    </motion.div>
  );
};

export const getStaticProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'error'])),
  },
});

export default Offline;
