export const OfflineLogo = () => {
  return (
    <svg
      width="74"
      height="16"
      viewBox="0 0 75 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.7253 8C28.7253 12.4183 25.1436 16 20.7253 16C16.307 16 12.7253 12.4183 12.7253 8C12.7253 3.58172 16.307 0 20.7253 0C25.1436 0 28.7253 3.58172 28.7253 8ZM25.8718 8C25.8718 10.8423 23.5676 13.1465 20.7253 13.1465C17.8829 13.1465 15.5788 10.8423 15.5788 8C15.5788 5.15767 17.8829 2.8535 20.7253 2.8535C23.5676 2.8535 25.8718 5.15767 25.8718 8Z"
        fill="white"
      />
      <path
        d="M4.38984 3.00505V15.5765H7.34413V3.00505H11.734V0.407643H0V3.00505H4.38984Z"
        fill="white"
      />
      <path
        d="M31.6501 0.407643V15.5765H34.6044V8.49076H35.9359L41.4908 15.5765H45.0901L38.3701 7.01544L43.7794 0.407643H40.3882L34.6044 7.49336V0.407643H31.6501Z"
        fill="white"
      />
      <path
        d="M46.8924 15.5765V0.407643H56.0882V2.90115H49.7843V6.62063H55.7137V9.11414H49.7843V13.083H56.2546V15.5765H46.8924Z"
        fill="white"
      />
      <path
        d="M60.1072 0.407637V15.5765H62.8534V2.85958H63.1447L69.5318 15.5765H74.4002V0.407637H71.6123V13.1245H71.3418L64.9547 0.407637H60.1072Z"
        fill="white"
      />
    </svg>
  );
};
