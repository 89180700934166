import jwtDecode from 'jwt-decode';
import { ReactNode, useState, useCallback, useEffect } from 'react';
import { CurrentUser, useAuth } from '../../hooks/useAuth';
import { DecodedToken } from '../../types/auth/Tokens';
import { authApiService } from '../../services/api/AuthApiService';
import { localStorageService } from '../../services/LocalStorageService';
import { TknBackendApiOption } from '../../types/enums/TknBackendApiOption';
import { differenceInMinutes, isPast } from 'date-fns';
import { testFanUser, testManagerUser, testStaffUser } from '../../types/User';
import { CircularProgress, Grid } from '@mui/material';
import { TokenUserRole } from '../../types/enums/TokenUserRole';
import { useRouter } from 'next/router';
import { AUTH_ROUTES } from '../../types/Routes';

interface RestoreSessionProps {
  children: ReactNode;
}

const ignoredRoutes = [...AUTH_ROUTES, () => '/logout'].map((route) => route());

const RestoreSessionView = () => {
  return (
    <Grid height="100vh" container justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );
};

export const RestoreSession = ({ children }: RestoreSessionProps) => {
  const { setUser, setIsUserLoggedIn, logout } = useAuth();
  const router = useRouter();
  const [showChildren, setShowChildren] = useState(false);
  const restoreSession = useCallback(async () => {
    try {
      const backend = localStorageService.getBackendApiOption();
      let user: CurrentUser = {
        ...testFanUser,
        ...testManagerUser,
        ...testStaffUser,
        userCreated: true,
        jobTitle: localStorageService.getCurrentStaffJob(),
        role: localStorageService.getCurrentUserRole(),
      };

      if (backend === TknBackendApiOption.REAL) {
        if (localStorageService.getGuestModeEnabled()) {
          user = {
            ...user,
            role: TokenUserRole.GUEST,
          };
        } else {
          if (
            ignoredRoutes.some((ignored) => router.asPath.startsWith(ignored))
          ) {
            throw new Error('Ignored route');
          }

          const token = localStorageService.getBearerToken();

          if (!token) {
            throw new Error('Token is not found');
          }

          const { exp }: DecodedToken = jwtDecode(token);
          const expiresAt = exp * 1000;

          // Check if token is expired or will expire in less than 5 minutes.
          const isExpired =
            isPast(expiresAt) || differenceInMinutes(expiresAt, Date.now()) < 5;

          if (isExpired) {
            throw new Error('Token is expired');
          }
          user = await authApiService.getCurrentUser();
        }
      }

      setUser(user);
      setIsUserLoggedIn(true);
      // console.log('Session restored', user);
    } catch (err) {
      console.error(err);
      logout();
    } finally {
      setShowChildren(true);
    }
  }, [setUser, setIsUserLoggedIn, logout, router]);

  useEffect(() => {
    restoreSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{showChildren ? children : <RestoreSessionView />}</>;
};
