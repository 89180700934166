import localforage from 'localforage';
import { initializeApp, getApps, getApp, FirebaseApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import * as Sentry from '@sentry/nextjs';

class PushNotificationService {
  private static readonly fcmTokenName = 'TKN_FCM_TOKEN';
  private static app: FirebaseApp | null = null;

  constructor() {
    if (!getApps().length) {
      PushNotificationService.app = initializeApp({
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
        measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
      });
    } else {
      PushNotificationService.app = getApp();
    }
  }

  public async getToken(): Promise<string> {
    try {
      if (PushNotificationService.app === null) {
        throw new Error('Firebase App is not initialized');
      }

      const token = await this.getFcmToken();
      if (token) {
        return token;
      }

      const messaging = getMessaging(PushNotificationService.app);
      const status = await Notification.requestPermission();

      if (status === 'granted') {
        const token = await getToken(messaging, {
          vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
        });
        await this.setFcmToken(token);
        return token;
      }

      console.warn('Notification permission denied');
      return '';
    } catch (error) {
      Sentry.captureException(error);
      return '';
    }
  }

  private async getFcmToken(): Promise<string | null> {
    return await localforage.getItem<string>(
      PushNotificationService.fcmTokenName
    );
  }

  private async setFcmToken(token: string): Promise<void> {
    await localforage.setItem(PushNotificationService.fcmTokenName, token);
  }
}

export const pushNotificationService = new PushNotificationService();
