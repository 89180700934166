import { Typography } from '@mui/material';
import { PushNotificationPayload } from '../../types/PushNotificationPayload';
import { TknSnackbarVariant } from './TknSnackbar';
import { TknSnackbarWithIcon } from './TknSnackbarWithIcon';
import React from 'react';

interface SnackbarWithIconNotificationProps {
  message: PushNotificationPayload;
  icon: React.ReactElement;
  iconColor: string;
  variant: TknSnackbarVariant;
  onClose?: () => void;
  onCloseIconClick?: () => void;
}

export const SnackbarWithIconNotification: React.FC<SnackbarWithIconNotificationProps> =
  ({ message, onClose, onCloseIconClick, icon, iconColor, variant }) => {
    return (
      <TknSnackbarWithIcon
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => onClose?.()}
        variant={variant}
        id={message.id}
        title={message.title}
        onCloseIconClick={() => onCloseIconClick?.()}
        icon={icon}
        iconColor={iconColor}
      >
        <Typography
          id={`message-${message.id}Body`}
          variant="descriptionRegular"
        >
          {message.body}
        </Typography>
      </TknSnackbarWithIcon>
    );
  };
