import {
  alpha,
  Box,
  CircularProgress,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import {
  useState,
  useCallback,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react';

interface PageThrobberContextType {
  showThrobber: () => void;
  hideThrobber: () => void;
  toggleThrobber: () => void;
}

interface PageThrobberProviderProps {
  children: ReactNode;
}

const PageThrobberContext = createContext<PageThrobberContextType>({
  showThrobber: () => {},
  hideThrobber: () => {},
  toggleThrobber: () => {},
});

interface PageThrobberStyle {
  container: (theme: Theme) => SxProps;
}

const style: PageThrobberStyle = {
  container: (theme: Theme) => ({
    top: 0,
    left: 0,
    position: 'fixed',
    display: 'grid',
    placeItems: 'center',
    width: '100vw',
    height: '100vh',
    zIndex: 99999999,
    background: alpha(theme.palette.global.black as string, 0.5),
  }),
};

export const PageThrobberProvider = ({
  children,
}: PageThrobberProviderProps) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const theme = useTheme();

  const showThrobber = useCallback(() => setIsShown(true), []);
  const hideThrobber = useCallback(() => setIsShown(false), []);
  const toggleThrobber = useCallback(() => setIsShown(!isShown), [isShown]);

  const values = useMemo(
    () => ({
      showThrobber,
      hideThrobber,
      toggleThrobber,
    }),
    [showThrobber, hideThrobber, toggleThrobber]
  );

  return (
    <PageThrobberContext.Provider value={values}>
      <AnimatePresence>
        {isShown && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box sx={style.container(theme)}>
              <CircularProgress />
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </PageThrobberContext.Provider>
  );
};

export const usePageThrobber = () => useContext(PageThrobberContext);
