import { CurrentUser } from '../hooks/useAuth';
import { TokenUserRole } from '../types/enums/TokenUserRole';
import isEmpty from 'lodash/isEmpty';

/**
 * Returns the home page url for the specified role.
 *
 * @param role user role
 * @returns path to the home page of the specified role
 */
export const getHomepagePath = (role?: TokenUserRole): string => {
  const pathPerRole = {
    [TokenUserRole.FAN]: '/fan/home',
    [TokenUserRole.MANAGER]: '/manager/home',
    [TokenUserRole.STAFF]: '/staff/home',
    [TokenUserRole.GUEST]: '/guest/home',
  };
  return role ? pathPerRole[role] : '/';
};

/**
 * Returns true if the user is a guest.
 *
 * @param user user to check
 * @returns true if the user is a guest
 */
export const isGuest = (user?: CurrentUser): boolean => {
  if (isEmpty(user)) {
    return true;
  }

  return user?.role === TokenUserRole.GUEST;
};
