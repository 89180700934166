import { Grid, Typography, SxProps } from '@mui/material';
import { TknSnackbar } from '../snackbars/TknSnackbar';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PushNotificationPayload } from '../../types/PushNotificationPayload';
import CloseIcon from '@mui/icons-material/Close';

interface BroadcastNotificationProps {
  message: PushNotificationPayload;
  onClose: () => void;
}

interface BroadcastNotificationStyle {
  container: SxProps;
}

const style: BroadcastNotificationStyle = {
  container: {
    padding: '18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '14px',
    flexWrap: 'nowrap',
  },
};

export const BroadcastNotification = ({
  message,
  onClose,
}: BroadcastNotificationProps) => {
  return (
    <TknSnackbar
      snackbarKey={message.id}
      open={true}
      variant="yellow"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Grid container sx={style.container}>
        <Grid item>
          <InfoOutlinedIcon />
        </Grid>
        <Grid item>
          <Typography variant="bodyMedium">{message.body}</Typography>
        </Grid>
        <Grid item>
          <CloseIcon onClick={onClose} />
        </Grid>
      </Grid>
    </TknSnackbar>
  );
};
