import { Grid, Typography, useTheme } from '@mui/material';
import { GetStaticProps } from 'next';
import { AppHeader } from '../components/AppHeader';
import { TknPageContainer } from '../components/TknPageContainer';
import { TknSvgIcon } from '../components/TknSvgIcon';
import { calculateiOSInset } from '../utils/tkn-util';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

const ClosedApplication = () => {
  const theme = useTheme();
  const { t } = useTranslation('error');
  return (
    <TknPageContainer
      textColor={theme.palette.global.white}
      background={theme.palette.global.radialRedBlueGradient}
    >
      <Grid container direction="column" gap={10}>
        <Grid item marginBottom={calculateiOSInset(96)}>
          <AppHeader
            backgroundColor="transparent"
            leftChild={
              <TknSvgIcon
                src="/icons/token-logo-white.svg"
                alt="Token Events name logo"
                height={16}
                width={74}
              />
            }
          />
        </Grid>
        <Grid item>
          <Typography variant="h3" marginBottom={2}>
            {t('closed.title', {
              defaultValue: 'Token Events is currently closed.',
            })}
          </Typography>
          <Typography component="p" marginBottom={2} variant="bodyRegular">
            {t('closed.description1', {
              defaultValue: 'Token Events is currently closed for all users.',
            })}
          </Typography>
          <Typography component="p" variant="bodyRegular">
            {t('closed.description2', {
              defaultValue: 'We apologize for this incovenience.',
            })}
          </Typography>
        </Grid>
      </Grid>
    </TknPageContainer>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale as string, [
        'common',
        'error',
      ])),
    },
  };
};

export default ClosedApplication;
