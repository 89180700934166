import { HttpService } from './HttpService';

export interface MXUpdatePayload {
  memberGuid: string;
  account?: {
    id: string;
    guid: string;
  };
}

class MXApiService extends HttpService {
  public readonly getWidgetUrlUrl = () => '/mx/url_widget';
  public readonly addMemberUrl = () => '/mx/add_member';

  public getWidgetUrl = async () => {
    return (await this.instance.get(this.getWidgetUrlUrl())).url;
  };

  public addMember = async (payload: MXUpdatePayload) => {
    return this.instance.post(this.addMemberUrl(), payload);
  };
}

export const mxApiService = new MXApiService();
