import { PushNotificationPayload } from '../../types/PushNotificationPayload';
import { TknDialogWithImage } from '../dialog/extensions/TknDialogWithImage';
import { useMemo } from 'react';
import { Typography } from '@mui/material';

interface PromotionAnnouncementData {
  image: string;
  button: string;
}

interface PromotionAnnoucementProps {
  message: PushNotificationPayload;
  onClose: () => void;
}

export const PromotionAnnouncement = ({
  message,
  onClose,
}: PromotionAnnoucementProps) => {
  const data: PromotionAnnouncementData = useMemo(() => {
    return JSON.parse(message.data);
  }, [message]);

  const actions = useMemo(
    () => [
      {
        label: data.button,
        action: onClose,
      },
    ],
    [data, onClose]
  );

  return (
    <TknDialogWithImage
      open={true}
      id={message.id}
      variant="dark"
      actions={actions}
      imageSrc={data.image}
    >
      <Typography variant="bodyRegular">{message.body}</Typography>
    </TknDialogWithImage>
  );
};
