export enum PushNotificationType {
  BROADCAST = 'BROADCAST',
  PROMOTION = 'PROMOTION',
  SCAN_SUCCESS = 'SCAN_SUCCESS',
  NFE_MINTED = 'NFE_MINTED',
  NFT_MINT_STARTED = 'NFT_MINT_STARTED',
  NFT_MINTED = 'NFT_MINTED',
  NFT_ERROR = 'NFT_ERROR',
  ENGAGEMENT_QUESTION = 'ENGAGEMENT_QUESTION',
}

export interface PushNotificationPayload {
  id: string;
  title: string;
  body: string;
  type: PushNotificationType;
  data: string; // Stringified JSON
}
