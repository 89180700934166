import { CurrentUser } from '../../hooks/useAuth';
import { UserPayload } from '../../pages/register';
import { ConfirmCodeResponse } from '../../types/auth/ConfirmCodeResponse';
import { GetJwtPayload } from '../../types/auth/GetJwtPayload';
import { ConfirmCodeAction } from '../../types/enums/ConfirmCodeAction';
import { HttpService } from './HttpService';

class AuthApiService extends HttpService {
  public readonly getTokenUrl = () => '/auth/get-jwt';
  public readonly sendOtpUrl = (actionType: ConfirmCodeAction) =>
    `/auth/confirm-otp-${actionType.toLowerCase()}`;
  public readonly createUserUrl = () => '/auth/create-user';
  public readonly getCurrentUserUrl = () => '/auth/get-logged-user';
  public getAuthToken = async (jwtPayload: GetJwtPayload) => {
    return this.instance.post(this.getTokenUrl(), jwtPayload);
  };

  public sendOtp = async (actionType: ConfirmCodeAction, otp: string) => {
    return this.instance.post<ConfirmCodeResponse>(
      this.sendOtpUrl(actionType),
      { otp }
    );
  };

  public createUser = async (createUserPayload: UserPayload) => {
    return this.instance.post(this.createUserUrl(), createUserPayload);
  };

  public getCurrentUser = async () => {
    return this.instance.get<CurrentUser>(this.getCurrentUserUrl());
  };
}

export const authApiService = new AuthApiService();
