import { HttpService } from './HttpService';
import { WalletInfo } from '../../types/wallet/WalletInfo';

class NftApiService extends HttpService {
  public readonly getWalletPath = '/wallet/notMintedAndMintedNFTs';
  private readonly getMintNftPath = (nfeId: string) =>
    `/nft/createAndMintNft/${nfeId}`;

  public getWallet = async (path: string): Promise<WalletInfo> => {
    return this.instance.get<WalletInfo>(path);
  };

  public mintNft = async (nfeId: string): Promise<void> => {
    return this.instance.post<void>(this.getMintNftPath(nfeId));
  };
}

export const nftApiService = new NftApiService();
