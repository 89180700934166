import { useRouter } from 'next/router';
import { useEffect, useState, ReactNode } from 'react';
import { useWindowSize } from 'react-use';
import { motion } from 'framer-motion';
import { localStorageService } from '../services/LocalStorageService';

const WIDTH_THRESHOLD = 1024;

interface PreRenderContainerProps {
  children: ReactNode;
}

function usePreRendering() {
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const { width } = useWindowSize();
  const router = useRouter();

  useEffect(() => {
    setIsRendering(false);
    const desktopOverridden = localStorageService.getDesktopOverride();
    const desktopWidth = width > WIDTH_THRESHOLD && router.asPath !== '/desktop';
    if (desktopWidth && !desktopOverridden) {
      router.push('/desktop');
    } else {
      setIsRendering(true);
    }
  }, [width, router]);

  return isRendering;
}

export function PreRenderContainer({ children }: PreRenderContainerProps) {
  const isRendering = usePreRendering();

  return isRendering ? (
    <motion.div
      key="pre-render-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  ) : null;
}
